import React from 'react';
import { Flex } from '@chakra-ui/react';

import InfoToggle from '~components/InfoToggle';
import LanguageSwitcher from '~components/LanguageSwitcher';
import EndDiscussion from './EndDiscussion';
import { IChatConnection } from '~types';

const DiscussionHeader: React.FC<IChatConnection> = (chatConnection) => {
  return (
    <Flex
      as="header"
      borderBottom="1px solid"
      borderColor="gray.300"
      height="4.625rem"
      justifyContent="flex-end"
      padding={4}
    >
      <LanguageSwitcher display={['none', null, 'block']} />

      <EndDiscussion {...chatConnection} />

      <InfoToggle display={['none', null, 'block']} />
    </Flex>
  );
};

export default DiscussionHeader;
