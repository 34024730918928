import { Box } from '@chakra-ui/react';
import React from 'react';
import { formatTime } from '~utils';
import TypingDot from './TypingDot';

interface TypingIndicatorProps {
  username: string;
}

const TypingIndicator: React.FC<TypingIndicatorProps> = ({ username }) => {
  const timeNow = new Date().toISOString();

  return (
    <Box aria-hidden marginTop={6}>
      <Box fontSize="sm">
        <strong>{username}</strong> - {formatTime(timeNow)}
      </Box>
      <Box>
        <TypingDot />
        <TypingDot />
        <TypingDot />
      </Box>
    </Box>
  );
};

export default TypingIndicator;
