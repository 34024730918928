import React from 'react';
import { Heading, Text } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';

import Container from '~components/Container';

const Intro: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Heading
        as="h1"
        fontSize={['2rem', null, '4xl']}
        lineHeight="125%"
        textAlign="center"
        color="brand.500"
        mt={8}
      >
        {t('intro.title')}
      </Heading>

      <Text textAlign="center" mt={4}>
        <Trans i18nKey="intro.paragraph">
          Kerro esitietoja, <strong>niin pääset hoitoon</strong>
        </Trans>
      </Text>
    </Container>
  );
};

export default Intro;
