import React, { Dispatch, SetStateAction } from 'react';
import { Textarea, Input, FormControl, FormLabel } from '@chakra-ui/react';

import { InputField, InputType } from '~api';

interface YedInputProps {
  inputValue: string | undefined;
  handleInputChange: Dispatch<SetStateAction<string | undefined>>;
}

const YedTextInput: React.FC<InputField & YedInputProps> = ({
  type,
  placeholder,
  label,
  inputValue,
  handleInputChange,
}) => {
  const inputField = (type: InputType) => {
    if (type === InputType.Textarea) {
      return (
        <Textarea
          value={inputValue}
          onChange={(e) => handleInputChange(e.target.value)}
          placeholder={placeholder ?? ''}
          rows={6}
        />
      );
    }

    return (
      <Input
        type={type === InputType.Email ? 'email' : 'text'}
        value={inputValue}
        onChange={(e) => handleInputChange(e.target.value)}
        placeholder={placeholder ?? ''}
        borderRadius="full"
      />
    );
  };

  return (
    <FormControl
      aria-label={label ?? ''}
      id={`yed-input-${type}`}
      pt={4}
      maxW="25rem"
      isRequired
    >
      {label && <FormLabel textAlign="center">{label}</FormLabel>}

      {inputField(type)}
    </FormControl>
  );
};
export default YedTextInput;
