import React from 'react';
import { Stack, StackProps, Text } from '@chakra-ui/react';
import { generateKey } from '~utils';

interface ParagraphStackProps {
  content?: [string];
  center?: boolean;
}

const ParagraphStack: React.FC<ParagraphStackProps & StackProps> = ({
  content,
  center = false,
  children,
  ...props
}) => {
  if (!children && !content) return null;

  const textAlign = center ? 'center' : 'left';

  if (content?.length === 1) {
    return (
      <>
        {content.map((paragraph, i) => (
          <Text
            key={generateKey('paragraph', i)}
            marginTop={props.marginTop ?? 0}
            maxWidth={props.maxWidth ?? undefined}
            textAlign={textAlign}
          >
            {paragraph}
          </Text>
        ))}
      </>
    );
  }

  return (
    <Stack spacing={4} textAlign={textAlign} {...props}>
      {content
        ? content.map((paragraph, i) => (
            <Text key={generateKey('paragraph', i)}>{paragraph}</Text>
          ))
        : children}
    </Stack>
  );
};

export default ParagraphStack;
