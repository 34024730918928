import React, { useEffect, useState } from 'react';
import {
  Heading,
  Stack,
  Image,
  Input,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Center,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import AuthSuccessImage from '/images/svg/authentication-success.svg';
import { CardContentStack } from '~components/Card';
import { generateKey } from '~utils';
import FlowItemSubmit from '../HealthIssueItemSubmit';
import { FlowResponse, Answer, Options, QuestionType } from '~api';

const HealthIssueAuthSuccess: React.FC<FlowResponse> = React.memo(
  ({ id, flowItem }) => {
    if (!id) return null;

    const { t } = useTranslation();
    const contactMethodOptions: [Options] = t(
      'healthIssueFlow.authenticationSuccess.contactMethodSelect.options',
      {
        returnObjects: true,
      },
    );
    const answers = flowItem?.answer;

    const handleOldAnswerValues = (answers: [Answer]) => {
      const oldAnswerValueByType = (type: QuestionType) =>
        answers.find((answer) => answer.type === type)?.value;

      const oldPhoneNumberValue = oldAnswerValueByType(QuestionType.Text);
      const oldContactMethodValue = oldAnswerValueByType(QuestionType.Radio);

      setPhoneNumberValue(() => oldPhoneNumberValue);
      setContactMethodValue(
        () => oldContactMethodValue ?? contactMethodOptions[0].value,
      );
    };

    const [phoneNumberValue, setPhoneNumberValue] = useState<any>('');
    const [contactMethodValue, setContactMethodValue] = useState<any>(
      contactMethodOptions[0].value,
    );
    const isValidPhoneNumber =
      !!phoneNumberValue && phoneNumberValue.length > 6;

    useEffect(() => {
      if (!answers || answers.length === 0) return;

      handleOldAnswerValues(answers as [Answer]);
    }, [answers]);

    const handlePhoneNumberChange = (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const value = event.target.value;

      return setPhoneNumberValue(value);
    };

    const variables = {
      id: id,
      answer: [
        {
          name: 'phone-number',
          type: QuestionType.Text,
          value: phoneNumberValue,
        },
        {
          name: 'contact-method',
          type: QuestionType.Radio,
          value: contactMethodValue,
        },
      ],
    };

    return (
      <>
        <CardContentStack as="form">
          <Image
            src={AuthSuccessImage}
            alt=""
            maxWidth={['170px', null, '230px']}
          />

          <Heading as="h1" textStyle="cardHeading">
            {t('healthIssueFlow.authenticationSuccess.title')}
          </Heading>

          <FormControl
            aria-label={t(
              'healthIssueFlow.authenticationSuccess.phoneInput.label',
            )}
            id="phone"
            pt={4}
            spacing={0}
            maxW="25rem"
            isRequired
          >
            <FormLabel textAlign="center">
              {t('healthIssueFlow.authenticationSuccess.phoneInput.label')}
            </FormLabel>

            <Input
              type="tel"
              value={phoneNumberValue}
              onChange={handlePhoneNumberChange}
              pattern="^[0-9+\s()]*$"
              placeholder={t(
                'healthIssueFlow.authenticationSuccess.phoneInput.placeholder',
              )}
              borderRadius="full"
              textAlign="center"
            />
          </FormControl>

          <Center>
            <FormControl
              aria-label={t(
                'healthIssueFlow.authenticationSuccess.contactMethodSelect.label',
              )}
              alignItems="center"
              p={[0, 4]}
            >
              <FormLabel as="legend" mb={2}>
                {t(
                  'healthIssueFlow.authenticationSuccess.contactMethodSelect.label',
                )}
              </FormLabel>

              <RadioGroup
                value={contactMethodValue}
                onChange={setContactMethodValue}
                aria-label={t(
                  'healthIssueFlow.authenticationSuccess.contactMethodSelect.label',
                )}
              >
                <Stack spacing={2}>
                  {contactMethodOptions.map(({ value, label }) => (
                    <Radio
                      key={generateKey(value)}
                      colorScheme="primary"
                      value={value}
                    >
                      {label}
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>
            </FormControl>
          </Center>
        </CardContentStack>

        <FlowItemSubmit
          variables={variables}
          disabled={!isValidPhoneNumber}
          type="submit"
        />
      </>
    );
  },
);

export default HealthIssueAuthSuccess;
