import React from 'react';
import { IconButton } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { BsArrowLeftShort } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';

const YedBackButton: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <IconButton
      icon={
        <BsArrowLeftShort
          fontSize="3rem"
          style={{ position: 'absolute', marginLeft: '-.1rem' }}
        />
      }
      aria-label={t('common.navigateBack')}
      variant="ghost"
      size="md"
      onClick={() => history.goBack()}
      background="none"
      borderRadius="full"
      cursor="pointer"
      left={3}
      padding="0"
      position="absolute"
      top={3}
      _hover={{ background: 'none' }}
    />
  );
};

export default YedBackButton;
