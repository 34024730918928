import { inputHeight } from '~theme/constants';
import { styles } from '~configurations';

export default {
  defaultProps: {
    size: 'lg',
  },
  sizes: {
    lg: {
      addon: {
        fontSize: 'md',
        h: inputHeight,
      },
      field: {
        fontSize: 'md',
        h: inputHeight,
      },
    },
  },
  variants: {
    outline: {
      field: {
        borderRadius: 'full',
        borderColor: styles.inputBorderColor,
        _hover: {
          borderColor: styles.inputBorderColor,
        },
      },
    },
  },
};
