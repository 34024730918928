import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

import { IChatStatusMessage } from '~types';

const ChatStatusNotification: React.FC<IChatStatusMessage> = ({
  title,
  message,
  isError,
}) => {
  if (!title && !isError) {
    return (
      <Text color="gray.400" fontWeight="bold" marginTop={6}>
        {message}
      </Text>
    );
  }

  return (
    <Box
      backgroundColor={isError ? 'orange.100' : 'blackAlpha.50'}
      border={isError ? '1px solid' : '2px dashed'}
      borderColor={isError ? 'red.200' : 'gray.300'}
      borderRadius={8}
      paddingX={5}
      paddingY={4}
      marginTop={6}
    >
      {title && (
        <Heading as="h3" size="sm" marginBottom={2}>
          {title}
        </Heading>
      )}

      <Text>{message}</Text>
    </Box>
  );
};

export default ChatStatusNotification;
