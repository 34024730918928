import { FlowResponse } from '~api';
import type { Socket } from 'socket.io-client';
import type { Colors, ShadowProps, SpaceProps } from '@chakra-ui/react';

export enum Configuration {
  Default = 'Default',
  Espoo = 'Espoo',
  Helsinki = 'Helsinki',
  Terapianavigaattori = 'Terapianavigaattori',
  Tupakkalakko = 'Tupakkalakko',
  Neuvola = 'Neuvola',
}

export enum FlowName {
  QAndA = 'search',
}

export enum QAndAFlowPath {
  Search = 'search',
  Results = 'results',
}

export enum ChatUsertype {
  Client = 'CLIENT',
  Professional = 'PROFESSIONAL',
}

export enum ChatSessionEndedReason {
  Error = 'ERROR',
  OtherUser = 'OTHER_USER',
  LeaveRequest = 'LEAVE_REQUEST',
}

export interface IChatConnection {
  socket: Socket | null;
  connectedUsertype: ChatUsertype;
  chatId: string;
}

export interface IChatUser {
  chatId: string;
  username: string;
  usertype: ChatUsertype;
  profession?: string;
  avatar?: string;
  time: string;
}
export interface IMessage {
  id: string;
  chatId: string;
  username: string;
  usertype: ChatUsertype;
  received: boolean;
  seen: boolean;
  time: string;
  text: string;
}

export interface IChatStatusMessage {
  title?: string;
  message: string;
  isError?: boolean;
}

export interface IState {
  modalIsOpen: boolean;
  appIsLoading: boolean;
  currentFlowItem?: FlowResponse;
}

export interface IAction {
  type: string;
  payload?: any;
}

export type Reducer = (state: IState, action: IAction) => IState;

export interface IStateContext {
  dispatch: (value: IAction) => void;
  state: IState;
}

// Extend Partial utility type to apply nested properties
export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export enum Locale {
  FI = 'fi',
  EN = 'en',
}

// TranslationKeys (nested translation keys, e.g. in the json translation file)
export type TranslationKeys = {
  [property: string]: string | TranslationKeys;
};

export type LanguageResources = {
  [key in Locale]?: TranslationKeys;
};

export interface IConfiguration {
  colors: Colors;
  header: {
    appLogo: string;
    appLogoWidth: string;
    showInfoToggle: boolean;
  };
  flowInit: {
    cardShadow: ShadowProps['shadow'];
    cardStackPadding: SpaceProps['pt'];
  };
  translations: {
    supportedLanguages: Locale[];
    resources: LanguageResources;
    fallbackLanguage: Locale;
    fallbackResources: Required<LanguageResources>;
  };
  chat: {
    enableChat: boolean;
    enableProChatView: boolean;
  };
  homeScreen: () => JSX.Element;
  styles: {
    inputBorderColor: string;
    searchBorderWidth: string;
  };
}

export type IConfigurationPartial = RecursivePartial<IConfiguration>;
