import { styles } from '~configurations';

export default {
  variants: {
    outline: {
      borderColor: styles.inputBorderColor,
      _hover: {
        borderColor: styles.inputBorderColor,
      },
    },
  },
};
