import React from 'react';
import { Heading, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { CardContentStack } from '~components/Card';
import HealthIssueItemSubmit from '~components/HealthIssueFlow/HealthIssueItemSubmit';
import ParagraphStack from '~components/ParagraphStack';
import SymptomIntroImage from '~images/svg/symptom-intro.svg';

const SymptomIntro: React.FC<{ answerId: string }> = ({ answerId }) => {
  if (!answerId) return null;

  const { t } = useTranslation();

  const variables = {
    id: answerId,
    answer: [],
  };

  const paragraphContent = Array.from(
    t('healthIssueFlow.symptomIntro.paragraphContent', {
      returnObjects: true,
    }),
  ) as [string];

  return (
    <>
      <CardContentStack as="form">
        <Heading as="h1" textStyle="cardHeading">
          {t('healthIssueFlow.symptomIntro.title')}
        </Heading>

        <Image
          src={SymptomIntroImage}
          alt=""
          maxWidth={['170px', null, '200px']}
          py={['4', null, '6']}
        />

        {paragraphContent && paragraphContent.length > 0 && (
          <ParagraphStack content={paragraphContent} center />
        )}
      </CardContentStack>

      <HealthIssueItemSubmit variables={variables} />
    </>
  );
};

export default SymptomIntro;
