import React from 'react';
import { Button, Heading, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import Card, { CardContentStack } from '~components/Card';
import HeaderImage from '~images/svg/generic-card-header.svg';
import EnigmaImage from '~images/svg/enigma.svg';
import { Link } from 'react-router-dom';

interface ErrorCardProps {
  title?: string;
  message?: string;
}

const ErrorCard: React.FC<ErrorCardProps> = ({ title, message }) => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardContentStack>
        <Image
          src={HeaderImage}
          alt=""
          display={['none', null, 'block']}
          maxWidth={[null, null, '200px', '250px']}
          py={[null, '4', '6']}
        />

        <Heading textStyle="cardHeading">
          {title ?? t('errors.errorCard.title')}
        </Heading>

        <Image src={EnigmaImage} alt="" maxWidth="70px" py={['4', null, '6']} />

        <Text>{message ?? t('errors.errorCard.paragraph')}</Text>
      </CardContentStack>

      <Button as={Link} to="/" width="100%" borderRadius={0}>
        {t('errors.errorCard.ctaLabel')}
      </Button>
    </Card>
  );
};

export default ErrorCard;
