import React from 'react';
import { Heading, Image, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { CardContentStack } from '~components/Card';
import HealthIssueItemSubmit from '~components/HealthIssueFlow/HealthIssueItemSubmit';
import HeaderImage from '~images/svg/generic-card-header.svg';
import { FlowResponse, ItemToOverview, OverviewQuestion } from '~api';
import QuestionItem from './QuestionItem';

const HealthIssueSymptomOverview: React.FC<FlowResponse> = ({
  id,
  flowItem,
}) => {
  const { t } = useTranslation();
  const question = flowItem?.question as OverviewQuestion;

  if (!id) return null;

  return (
    <>
      <CardContentStack>
        <Image
          src={HeaderImage}
          alt=""
          display={['none', null, 'block']}
          marginBottom={['1rem', null, '0']}
          maxWidth={[null, null, '200px', '250px']}
          py={[null, null, '4', '6']}
        />

        <Heading as="h1" textStyle="cardHeading" style={{ marginTop: '0' }}>
          {t('healthIssueFlow.symptomOverview.title')}
        </Heading>

        <Text>{t('healthIssueFlow.symptomOverview.paragraph')}</Text>

        <VStack alignItems="flex-start" pt={6} width="100%" spacing={4}>
          {question?.items.map((item, i: number) => {
            if (item && item.flowItemName.indexOf('alarming-symptom') > -1) {
              return null;
            }

            return (
              <QuestionItem
                key={`symptom-overview-question-item-${i}`}
                id={id}
                item={item as ItemToOverview}
              />
            );
          })}
        </VStack>
      </CardContentStack>

      <HealthIssueItemSubmit
        variables={{
          id: id,
          answer: [],
        }}
      />
    </>
  );
};

export default HealthIssueSymptomOverview;
