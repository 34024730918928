import * as React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const SkinIcon: React.FC<IconProps> = ({ ...props }) => {
  return (
    <Icon {...props}>
      <g fill="none">
        <path
          d="M48.264 19.273a4.199 4.199 0 00-3.374-1.846.593.593 0 00-.578.39l-3.976 10.966a1.18 1.18 0 01-1.514.71 1.187 1.187 0 01-.706-1.521l5.253-14.489a4.182 4.182 0 00-.451-3.736A4.201 4.201 0 0039.544 7.9a.593.593 0 00-.578.39L33.778 22.6a1.18 1.18 0 01-1.514.709 1.187 1.187 0 01-.706-1.52l5.656-15.603a4.16 4.16 0 00-2.47-5.321 3.818 3.818 0 00-1.392-.239.588.588 0 00-.548.388l-6.796 18.747a1.18 1.18 0 01-1.514.708 1.187 1.187 0 01-.706-1.52l4.04-11.145a4.164 4.164 0 00-.718-4.088 4.125 4.125 0 00-7.052 1.249l-8.08 22.29a.598.598 0 01-.603.393.582.582 0 01-.525-.444L8.544 18.86a4.097 4.097 0 00-5.04-2.873A4.118 4.118 0 00.64 21.049l5.907 21.644a7.709 7.709 0 004.788 5.198l11.18 4.086c7.66 2.78 16.116-1.184 18.912-8.866l7.287-20.101a4.182 4.182 0 00-.451-3.737z"
          fill="#F0F7FE"
        />
        <path
          d="M46.797 33.688a2.668 2.668 0 00-2.142-1.168.376.376 0 00-.367.246l-2.524 6.935a.75.75 0 01-1.41-.513l3.335-9.162a2.636 2.636 0 00-.286-2.363 2.67 2.67 0 00-2.142-1.167.376.376 0 00-.367.246l-3.294 9.05a.75.75 0 01-1.41-.514l3.592-9.866a2.628 2.628 0 00-1.57-3.365 2.433 2.433 0 00-.883-.15.373.373 0 00-.348.245l-4.315 11.854a.75.75 0 01-1.409-.513l2.565-7.048a2.625 2.625 0 00-4.933-1.796l-5.13 14.096a.38.38 0 01-.383.248.369.369 0 01-.334-.28l-1.464-5.276a2.603 2.603 0 00-5.018 1.384l3.75 13.686a4.883 4.883 0 003.04 3.287l7.1 2.584a9.387 9.387 0 0012.007-5.606l4.626-12.711a2.636 2.636 0 00-.286-2.363z"
          fill="#0047B6"
        />
      </g>
    </Icon>
  );
};
