import React, { useContext, useState } from 'react';
import { Button, Heading, Image, Link, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import AlarmingSymptomImage from '~images/svg/alarming-symptom.svg';
import { CardContentStack } from '~components/Card';
import { AnswerInput, Exact, useAddAnswerMutation } from '~api';
import { AppContext } from '~state';
import { healthIssueRoot, closeSessionAnswerObject } from '~constants';
import { useHistory } from 'react-router-dom';

const HealthIssueAlarmingSymptom: React.FC<{ answerId: string }> = ({
  answerId,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { dispatch } = useContext(AppContext);
  const [endFlow, setEndFlow] = useState(false);

  const [addAnswer, { loading }] = useAddAnswerMutation({
    onError() {
      dispatch({
        type: 'SET_APP_LOADING_STATE',
        payload: false,
      });
    },
    onCompleted(data) {
      dispatch({
        type: 'SET_CURRENT_FLOW_ITEM',
        payload: data.addAnswer,
      });

      if (endFlow) {
        history.push('/');

        return dispatch({
          type: 'SET_APP_LOADING_STATE',
          payload: false,
        });
      }

      history.push(
        `/${healthIssueRoot}/${data.addAnswer.flowItem?.name}/${data.addAnswer.id}`,
      );

      return dispatch({
        type: 'SET_APP_LOADING_STATE',
        payload: false,
      });
    },
  });

  const handleResponse = (
    variables: Exact<{
      id: string;
      answer: AnswerInput | AnswerInput[];
    }>,
  ) => {
    dispatch({
      type: 'SET_APP_LOADING_STATE',
      payload: false,
    });

    return addAnswer({ variables });
  };

  return (
    <>
      <CardContentStack mb={[4, null, 8]}>
        <Image
          src={AlarmingSymptomImage}
          alt=""
          maxWidth={['170px', null, '230px']}
        />

        <Heading as="h1" textStyle="cardHeading" py={['4', null, '8']}>
          {t('healthIssueFlow.alarmingSymptom.title')}
        </Heading>

        <Text textAlign="center">
          {t('healthIssueFlow.alarmingSymptom.paragraph')}
        </Text>

        <Heading as="h2" size="sm" textAlign="center">
          {t('healthIssueFlow.alarmingSymptom.emergencyCarePrimaryLabel')}
        </Heading>

        <Text
          size="lg"
          fontWeight="bold"
          textAlign="center"
          // Margin does not get overriden using style prop
          style={{ marginTop: 0 }}
        >
          <Link
            href={`tel:${t(
              'healthIssueFlow.alarmingSymptom.emergencyCarePrimaryPhoneNumber',
            )}`}
          >
            {t(
              'healthIssueFlow.alarmingSymptom.emergencyCarePrimaryPhoneNumber',
            )}
          </Link>
        </Text>

        <Heading as="h2" size="sm" textAlign="center">
          {t('healthIssueFlow.alarmingSymptom.emergencyNumberLabel')}
        </Heading>

        <Text
          size="lg"
          fontWeight="bold"
          textAlign="center"
          // Margin does not get overriden using style prop
          style={{ marginTop: 0 }}
        >
          <Link href="tel:112">112</Link>
        </Text>

        <VStack spacing="0.625rem">
          <Button
            isLoading={loading && endFlow}
            onClick={() => {
              setEndFlow(true);
              return handleResponse({
                id: answerId,
                answer: closeSessionAnswerObject,
              });
            }}
            width="100%"
          >
            {t('healthIssueFlow.alarmingSymptom.submitLabel')}
          </Button>

          <Button
            isLoading={loading && !endFlow}
            onClick={() => handleResponse({ id: answerId, answer: [] })}
            colorScheme="red"
            whiteSpace="normal"
            width="100%"
          >
            {t('healthIssueFlow.alarmingSymptom.submitContinueLabel')}
          </Button>
        </VStack>
      </CardContentStack>
    </>
  );
};

export default HealthIssueAlarmingSymptom;
