import * as React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const StomachAcheIcon: React.FC<IconProps> = ({ ...props }) => {
  return (
    <Icon {...props}>
      <g fill="none">
        <path
          d="M0 55.417A.59.59 0 00.598 56h8.37a.59.59 0 00.597-.583v-4.084a4.557 4.557 0 011.377-3.266 1.217 1.217 0 011.686-.014c7.87 7.462 19.857 8.927 29.37 3.588 8.143-4.607 13.112-13.138 13-22.323v-.151c0-12.873-10.684-23.314-23.876-23.334-2.65.006-4.804-2.081-4.819-4.666V.583A.59.59 0 0025.706 0h-8.37a.59.59 0 00-.597.583v1.853C16.739 8.823 22.044 14 28.589 14h5.486c.99 0 1.793.784 1.793 1.75s-.803 1.75-1.793 1.75H29.85a1.184 1.184 0 00-1.196 1.09C27.26 39.494 0 36.034 0 49v6.417z"
          fill="#F0F7FE"
        />
        <path
          d="M18 55.604c0 .219.18.396.402.396h5.63a.399.399 0 00.403-.396v-2.77a3.105 3.105 0 01.926-2.217.813.813 0 011.134-.01c5.294 5.064 13.358 6.058 19.758 2.435 5.478-3.126 8.82-8.915 8.746-15.147v-.103c0-8.736-7.188-15.82-16.063-15.834-1.782.004-3.231-1.412-3.24-3.166v-.396a.399.399 0 00-.403-.396h-5.63a.399.399 0 00-.403.396v1.257c0 4.334 3.57 7.847 7.973 7.847h3.69c.666 0 1.207.532 1.207 1.188 0 .655-.54 1.187-1.207 1.187h-2.842a.8.8 0 00-.805.74C36.34 44.8 18 42.45 18 51.25v4.354z"
          fill="#0047B6"
        />
      </g>
    </Icon>
  );
};
