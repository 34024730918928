import { buttonHeight } from '~theme/constants';

export default {
  defaultProps: {
    colorScheme: 'primary',
    size: 'lg',
  },
  baseStyle: {
    borderRadius: 'full',
    fontWeight: 'bold',
    _disabled: {
      opacity: 0.5,
    },
  },
  sizes: {
    lg: {
      fontSize: 'md',
      h: buttonHeight,
      minWidth: '15rem',
    },
  },
  variants: {
    outline: {
      bg: 'rgba(0, 71, 182, .06)',
      borderWidth: '2px',
      _hover: {
        bg: 'rgba(0, 71, 182, .10)',
      },
    },
    link: {
      minWidth: 'unset',
    },
  },
};
