import { Container as ChakraContainer, BoxProps } from '@chakra-ui/react';
import React from 'react';

const Container: React.FC<BoxProps> = ({ ...props }) => {
  return (
    <ChakraContainer {...props} p={props.padding ?? 4}>
      {props.children}
    </ChakraContainer>
  );
};

export default Container;
