/** THIS FILE IS GENERATED WITH GRAPHQL-CODEGEN **/
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};


export type Query = {
  __typename?: 'Query';
  start: FlowResponse;
  startQuestion: FlowResponse;
  flowItem: FlowResponse;
  contentSearch: Array<Maybe<Options>>;
  startMenu: Array<Maybe<MenuItem>>;
};


export type QueryStartArgs = {
  name?: Maybe<Scalars['String']>;
};


export type QueryStartQuestionArgs = {
  questionName?: Maybe<Scalars['String']>;
};


export type QueryFlowItemArgs = {
  id: Scalars['ID'];
};


export type QueryContentSearchArgs = {
  query?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addAnswer: FlowResponse;
};


export type MutationAddAnswerArgs = {
  id: Scalars['ID'];
  answer: Array<AnswerInput>;
};

export type MenuItem = {
  __typename?: 'MenuItem';
  /** Translation key of the menu item text. If not present use label instead */
  translationKey?: Maybe<Scalars['String']>;
  /** Label for the menu item */
  label?: Maybe<Scalars['String']>;
  /** Action of the menu item */
  action: Action;
  /** Action parameter for applicable actions */
  actionParameter?: Maybe<Scalars['String']>;
};

export enum Action {
  /** Start chat flow */
  Chat = 'CHAT',
  /** No action, menu item is disabled. Menu item should be shown greyed out */
  Disabled = 'DISABLED',
  /** Show question using `startQuestion` query */
  Question = 'QUESTION',
  /** Start search flow using `start` query. Use actionParameter if given */
  Search = 'SEARCH',
  /** Start symptom flow using `start` query. Use actionParameter if given */
  Symptom = 'SYMPTOM',
  /** Go to url given in actionParameter */
  Url = 'URL'
}

export type FlowResponse = {
  __typename?: 'FlowResponse';
  id?: Maybe<Scalars['ID']>;
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  flowItem?: Maybe<FlowItem>;
};

export type FlowItem = {
  __typename?: 'FlowItem';
  name: Scalars['String'];
  type: FlowItemType;
  isDebug?: Maybe<Scalars['Boolean']>;
  currentStep?: Maybe<Scalars['Int']>;
  totalSteps?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Array<Maybe<Scalars['String']>>>;
  question?: Maybe<Question>;
  answer?: Maybe<Array<Maybe<Answer>>>;
};

export type Question = SelectQuestion | OverviewQuestion | YedQuestion;

export type SelectQuestion = {
  __typename?: 'SelectQuestion';
  options: Array<Maybe<Options>>;
  shortcuts?: Maybe<Array<Maybe<Options>>>;
};

export type OverviewQuestion = {
  __typename?: 'OverviewQuestion';
  items: Array<Maybe<ItemToOverview>>;
};

export type ItemToOverview = {
  __typename?: 'ItemToOverview';
  /**
   * Name of the flowItem which is reviewed. User's answer to flowItem is
   * displayed and if user wants to change the answer `flowItemName` value
   * should be returned.
   */
  flowItemName: Scalars['String'];
  /**
   * If question value is `null` then it should be obtained from the title
   * of flowItem with name `flowItemName`
   */
  question?: Maybe<Scalars['String']>;
  /**
   * User's selection on the flowItem. This is internal value (e.g. slider
   * position) and it should be converted to a human-readable string before
   * showing to the user.
   */
  userSelection?: Maybe<Scalars['String']>;
  /**
   * User's input which can be shown directly to the user. Usually text field
   * content.
   */
  userInput?: Maybe<Scalars['String']>;
};

export type YedQuestion = {
  __typename?: 'YedQuestion';
  /** Type of input field if such field is present */
  input?: Maybe<InputField>;
  /** One or more buttons for user to click */
  buttons: Array<Maybe<Button>>;
  /** Zero or more external links */
  externalLinks: Array<Maybe<ExternalLink>>;
};

export type InputField = {
  __typename?: 'InputField';
  type: InputType;
  placeholder?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  /** If type is CHECKBOX or RADIO this contains options for it */
  options?: Maybe<Array<Maybe<Options>>>;
};

export type Button = {
  __typename?: 'Button';
  text: Scalars['String'];
  value: Scalars['String'];
};

export type ExternalLink = {
  __typename?: 'ExternalLink';
  /** Link text shown to user */
  text: Scalars['String'];
  /** Url link points to */
  url: Scalars['String'];
  /** Value of the link, can be used to keep track of clicked external links */
  value: Scalars['String'];
  /** Should link open in new window */
  newWindow: Scalars['Boolean'];
};

export type Options = {
  __typename?: 'Options';
  value: Scalars['String'];
  label: Scalars['String'];
  imageId?: Maybe<Scalars['String']>;
};

export type Answer = {
  __typename?: 'Answer';
  name: Scalars['String'];
  type: QuestionType;
  value?: Maybe<Scalars['String']>;
};

export type AnswerInput = {
  name: Scalars['String'];
  type: QuestionType;
  value?: Maybe<Scalars['String']>;
};

export enum FlowItemType {
  AlarmingSymptom = 'ALARMING_SYMPTOM',
  Authentication = 'AUTHENTICATION',
  AuthenticationSuccess = 'AUTHENTICATION_SUCCESS',
  BasicInfo = 'BASIC_INFO',
  Error = 'ERROR',
  FastlaneIssueDescription = 'FASTLANE_ISSUE_DESCRIPTION',
  Feedback = 'FEEDBACK',
  HealthIssueDescription = 'HEALTH_ISSUE_DESCRIPTION',
  HealthIssueDuration = 'HEALTH_ISSUE_DURATION',
  HealthIssueInfo = 'HEALTH_ISSUE_INFO',
  Instructions = 'INSTRUCTIONS',
  QaResult = 'QA_RESULT',
  QaSearch = 'QA_SEARCH',
  SessionClosed = 'SESSION_CLOSED',
  SymptomIntro = 'SYMPTOM_INTRO',
  SymptomOverview = 'SYMPTOM_OVERVIEW',
  SymptomQuestion = 'SYMPTOM_QUESTION',
  SymptomQuestionSimple = 'SYMPTOM_QUESTION_SIMPLE',
  SymptomQuestionSimpleWithDetails = 'SYMPTOM_QUESTION_SIMPLE_WITH_DETAILS',
  SymptomSelect = 'SYMPTOM_SELECT',
  ThankForAnswers = 'THANK_FOR_ANSWERS',
  YedQuestion = 'YED_QUESTION'
}

export enum QuestionType {
  Age = 'AGE',
  ButtonOptions = 'BUTTON_OPTIONS',
  Email = 'EMAIL',
  Gender = 'GENDER',
  Link = 'LINK',
  Number = 'NUMBER',
  Radio = 'RADIO',
  Checkbox = 'CHECKBOX',
  Search = 'SEARCH',
  SearchQuickLinks = 'SEARCH_QUICK_LINKS',
  SelectWithShortcuts = 'SELECT_WITH_SHORTCUTS',
  Slider = 'SLIDER',
  Special = 'SPECIAL',
  Text = 'TEXT'
}

export enum InputType {
  Text = 'TEXT',
  Textarea = 'TEXTAREA',
  Email = 'EMAIL',
  Checkbox = 'CHECKBOX',
  Radio = 'RADIO'
}

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}


export type AnswerFragment = (
  { __typename?: 'Answer' }
  & Pick<Answer, 'name' | 'type' | 'value'>
);

export type OptionsFragment = (
  { __typename?: 'Options' }
  & Pick<Options, 'label' | 'value' | 'imageId'>
);

export type ItemToOverviewFragment = (
  { __typename?: 'ItemToOverview' }
  & Pick<ItemToOverview, 'flowItemName' | 'question' | 'userSelection' | 'userInput'>
);

export type FlowItemFieldsFragment = (
  { __typename?: 'FlowItem' }
  & Pick<FlowItem, 'name' | 'type' | 'isDebug' | 'currentStep' | 'totalSteps' | 'title' | 'description'>
  & { question?: Maybe<(
    { __typename: 'SelectQuestion' }
    & { options: Array<Maybe<(
      { __typename?: 'Options' }
      & OptionsFragment
    )>>, shortcuts?: Maybe<Array<Maybe<(
      { __typename?: 'Options' }
      & OptionsFragment
    )>>> }
  ) | (
    { __typename: 'OverviewQuestion' }
    & { items: Array<Maybe<(
      { __typename?: 'ItemToOverview' }
      & ItemToOverviewFragment
    )>> }
  ) | (
    { __typename: 'YedQuestion' }
    & { input?: Maybe<(
      { __typename?: 'InputField' }
      & Pick<InputField, 'type' | 'label' | 'placeholder'>
      & { options?: Maybe<Array<Maybe<(
        { __typename?: 'Options' }
        & OptionsFragment
      )>>> }
    )>, buttons: Array<Maybe<(
      { __typename?: 'Button' }
      & Pick<Button, 'text' | 'value'>
    )>>, externalLinks: Array<Maybe<(
      { __typename?: 'ExternalLink' }
      & Pick<ExternalLink, 'text' | 'url' | 'value' | 'newWindow'>
    )>> }
  )>, answer?: Maybe<Array<Maybe<(
    { __typename?: 'Answer' }
    & AnswerFragment
  )>>> }
);

export type FlowResponseFragment = (
  { __typename: 'FlowResponse' }
  & Pick<FlowResponse, 'id' | 'success' | 'message'>
  & { flowItem?: Maybe<(
    { __typename?: 'FlowItem' }
    & FlowItemFieldsFragment
  )> }
);

export type StartFlowQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
}>;


export type StartFlowQuery = (
  { __typename?: 'Query' }
  & { start: (
    { __typename?: 'FlowResponse' }
    & FlowResponseFragment
  ) }
);

export type GetFlowItemQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetFlowItemQuery = (
  { __typename?: 'Query' }
  & { flowItem: (
    { __typename?: 'FlowResponse' }
    & FlowResponseFragment
  ) }
);

export type ContentSearchQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
}>;


export type ContentSearchQuery = (
  { __typename?: 'Query' }
  & { contentSearch: Array<Maybe<(
    { __typename?: 'Options' }
    & OptionsFragment
  )>> }
);

export type AddAnswerMutationVariables = Exact<{
  id: Scalars['ID'];
  answer: Array<AnswerInput> | AnswerInput;
}>;


export type AddAnswerMutation = (
  { __typename?: 'Mutation' }
  & { addAnswer: (
    { __typename?: 'FlowResponse' }
    & FlowResponseFragment
  ) }
);

export type GetStartMenuQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStartMenuQuery = (
  { __typename?: 'Query' }
  & { startMenu: Array<Maybe<(
    { __typename?: 'MenuItem' }
    & Pick<MenuItem, 'translationKey' | 'label' | 'action' | 'actionParameter'>
  )>> }
);

export type StartQuestionQueryVariables = Exact<{
  questionName?: Maybe<Scalars['String']>;
}>;


export type StartQuestionQuery = (
  { __typename?: 'Query' }
  & { startQuestion: (
    { __typename?: 'FlowResponse' }
    & FlowResponseFragment
  ) }
);

export const OptionsFragmentDoc = gql`
    fragment options on Options {
  label
  value
  imageId
}
    `;
export const ItemToOverviewFragmentDoc = gql`
    fragment itemToOverview on ItemToOverview {
  flowItemName
  question
  userSelection
  userInput
}
    `;
export const AnswerFragmentDoc = gql`
    fragment answer on Answer {
  name
  type
  value
}
    `;
export const FlowItemFieldsFragmentDoc = gql`
    fragment flowItemFields on FlowItem {
  name
  type
  isDebug
  currentStep
  totalSteps
  title
  description
  question {
    __typename
    ... on SelectQuestion {
      options {
        ...options
      }
      shortcuts {
        ...options
      }
    }
    ... on OverviewQuestion {
      items {
        ...itemToOverview
      }
    }
    ... on YedQuestion {
      input {
        type
        label
        placeholder
        options {
          ...options
        }
      }
      buttons {
        text
        value
      }
      externalLinks {
        text
        url
        value
        newWindow
      }
    }
  }
  answer {
    ...answer
  }
}
    ${OptionsFragmentDoc}
${ItemToOverviewFragmentDoc}
${AnswerFragmentDoc}`;
export const FlowResponseFragmentDoc = gql`
    fragment flowResponse on FlowResponse {
  __typename
  id
  success
  message
  flowItem {
    ...flowItemFields
  }
}
    ${FlowItemFieldsFragmentDoc}`;
export const StartFlowDocument = gql`
    query StartFlow($name: String) {
  start(name: $name) {
    ...flowResponse
  }
}
    ${FlowResponseFragmentDoc}`;

/**
 * __useStartFlowQuery__
 *
 * To run a query within a React component, call `useStartFlowQuery` and pass it any options that fit your needs.
 * When your component renders, `useStartFlowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStartFlowQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useStartFlowQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StartFlowQuery, StartFlowQueryVariables>) {
        return ApolloReactHooks.useQuery<StartFlowQuery, StartFlowQueryVariables>(StartFlowDocument, baseOptions);
      }
export function useStartFlowLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StartFlowQuery, StartFlowQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StartFlowQuery, StartFlowQueryVariables>(StartFlowDocument, baseOptions);
        }
export type StartFlowQueryHookResult = ReturnType<typeof useStartFlowQuery>;
export type StartFlowLazyQueryHookResult = ReturnType<typeof useStartFlowLazyQuery>;
export type StartFlowQueryResult = Apollo.QueryResult<StartFlowQuery, StartFlowQueryVariables>;
export const GetFlowItemDocument = gql`
    query GetFlowItem($id: ID!) {
  flowItem(id: $id) {
    ...flowResponse
  }
}
    ${FlowResponseFragmentDoc}`;

/**
 * __useGetFlowItemQuery__
 *
 * To run a query within a React component, call `useGetFlowItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlowItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlowItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFlowItemQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetFlowItemQuery, GetFlowItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFlowItemQuery, GetFlowItemQueryVariables>(GetFlowItemDocument, baseOptions);
      }
export function useGetFlowItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFlowItemQuery, GetFlowItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFlowItemQuery, GetFlowItemQueryVariables>(GetFlowItemDocument, baseOptions);
        }
export type GetFlowItemQueryHookResult = ReturnType<typeof useGetFlowItemQuery>;
export type GetFlowItemLazyQueryHookResult = ReturnType<typeof useGetFlowItemLazyQuery>;
export type GetFlowItemQueryResult = Apollo.QueryResult<GetFlowItemQuery, GetFlowItemQueryVariables>;
export const ContentSearchDocument = gql`
    query ContentSearch($query: String) {
  contentSearch(query: $query) {
    ...options
  }
}
    ${OptionsFragmentDoc}`;

/**
 * __useContentSearchQuery__
 *
 * To run a query within a React component, call `useContentSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useContentSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContentSearchQuery, ContentSearchQueryVariables>) {
        return ApolloReactHooks.useQuery<ContentSearchQuery, ContentSearchQueryVariables>(ContentSearchDocument, baseOptions);
      }
export function useContentSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContentSearchQuery, ContentSearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ContentSearchQuery, ContentSearchQueryVariables>(ContentSearchDocument, baseOptions);
        }
export type ContentSearchQueryHookResult = ReturnType<typeof useContentSearchQuery>;
export type ContentSearchLazyQueryHookResult = ReturnType<typeof useContentSearchLazyQuery>;
export type ContentSearchQueryResult = Apollo.QueryResult<ContentSearchQuery, ContentSearchQueryVariables>;
export const AddAnswerDocument = gql`
    mutation AddAnswer($id: ID!, $answer: [AnswerInput!]!) {
  addAnswer(id: $id, answer: $answer) {
    ...flowResponse
  }
}
    ${FlowResponseFragmentDoc}`;
export type AddAnswerMutationFn = Apollo.MutationFunction<AddAnswerMutation, AddAnswerMutationVariables>;

/**
 * __useAddAnswerMutation__
 *
 * To run a mutation, you first call `useAddAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAnswerMutation, { data, loading, error }] = useAddAnswerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      answer: // value for 'answer'
 *   },
 * });
 */
export function useAddAnswerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddAnswerMutation, AddAnswerMutationVariables>) {
        return ApolloReactHooks.useMutation<AddAnswerMutation, AddAnswerMutationVariables>(AddAnswerDocument, baseOptions);
      }
export type AddAnswerMutationHookResult = ReturnType<typeof useAddAnswerMutation>;
export type AddAnswerMutationResult = Apollo.MutationResult<AddAnswerMutation>;
export type AddAnswerMutationOptions = Apollo.BaseMutationOptions<AddAnswerMutation, AddAnswerMutationVariables>;
export const GetStartMenuDocument = gql`
    query GetStartMenu {
  startMenu {
    translationKey
    label
    action
    actionParameter
  }
}
    `;

/**
 * __useGetStartMenuQuery__
 *
 * To run a query within a React component, call `useGetStartMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStartMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStartMenuQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStartMenuQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStartMenuQuery, GetStartMenuQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStartMenuQuery, GetStartMenuQueryVariables>(GetStartMenuDocument, baseOptions);
      }
export function useGetStartMenuLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStartMenuQuery, GetStartMenuQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStartMenuQuery, GetStartMenuQueryVariables>(GetStartMenuDocument, baseOptions);
        }
export type GetStartMenuQueryHookResult = ReturnType<typeof useGetStartMenuQuery>;
export type GetStartMenuLazyQueryHookResult = ReturnType<typeof useGetStartMenuLazyQuery>;
export type GetStartMenuQueryResult = Apollo.QueryResult<GetStartMenuQuery, GetStartMenuQueryVariables>;
export const StartQuestionDocument = gql`
    query StartQuestion($questionName: String) {
  startQuestion(questionName: $questionName) {
    ...flowResponse
  }
}
    ${FlowResponseFragmentDoc}`;

/**
 * __useStartQuestionQuery__
 *
 * To run a query within a React component, call `useStartQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useStartQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStartQuestionQuery({
 *   variables: {
 *      questionName: // value for 'questionName'
 *   },
 * });
 */
export function useStartQuestionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StartQuestionQuery, StartQuestionQueryVariables>) {
        return ApolloReactHooks.useQuery<StartQuestionQuery, StartQuestionQueryVariables>(StartQuestionDocument, baseOptions);
      }
export function useStartQuestionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StartQuestionQuery, StartQuestionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StartQuestionQuery, StartQuestionQueryVariables>(StartQuestionDocument, baseOptions);
        }
export type StartQuestionQueryHookResult = ReturnType<typeof useStartQuestionQuery>;
export type StartQuestionLazyQueryHookResult = ReturnType<typeof useStartQuestionLazyQuery>;
export type StartQuestionQueryResult = Apollo.QueryResult<StartQuestionQuery, StartQuestionQueryVariables>;