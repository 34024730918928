import React, { useEffect, useState } from 'react';
import {
  Heading,
  Stack,
  Textarea,
  Button,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { BiPlusCircle } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';

import ParagraphStack from '~components/ParagraphStack';
import Slider from '~components/formComponents/Slider';
import { CardContentStack } from '~components/Card';
import FlowItemSubmit from '../HealthIssueItemSubmit';
import ButtonOptions from '~components/formComponents/ButtonOptions';
import { useParams } from 'react-router';
import { Answer, FlowItem, QuestionType } from '~api';
import { setDurationOptions } from '~utils';
import FastlaneButton from '../FastlaneButton';

type HealhtIssueQuestionProps = FlowItem & {
  showSlider?: boolean;
  showDetailsToggle?: boolean;
};

const shouldShowDetails = (symptomValue: string, showSlider: boolean) => {
  if (
    symptomValue === 'yes' ||
    (showSlider && (symptomValue === 'no' || symptomValue === 'yes'))
  ) {
    return true;
  }

  return false;
};

const HealthIssueQuestion: React.FC<HealhtIssueQuestionProps> = React.memo(
  ({
    title,
    name,
    description,
    answer,
    showSlider = false,
    showDetailsToggle,
  }) => {
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const [symptomValue, setSymptomValue] = useState<any>();
    const [sliderValue, setSliderValue] = useState<any>();
    const [inputValue, setInputValue] = useState<any>();
    const [inputIsVisible, setInputVisibility] = useState(false);
    const showDetails = shouldShowDetails(symptomValue, showSlider);
    const showInput = inputIsVisible || inputValue || !showDetailsToggle;
    const sliderOptions = setDurationOptions();

    const theAnswers = [
      {
        type: QuestionType.ButtonOptions,
        name: name,
        value: symptomValue,
      },
      {
        type: QuestionType.Text,
        name: 'additional-info',
        value: inputValue ?? '',
      },
    ];

    if (showSlider)
      theAnswers?.push({
        type: QuestionType.Slider,
        name: 'symptom-duration',
        value: symptomValue === 'yes' ? sliderValue?.toString() ?? '' : '',
      });

    const variables = {
      id: id,
      answer: theAnswers,
    };

    const localizedOptions = t('healthIssueFlow.question.responseOptions', {
      returnObjects: true,
    });

    const responseOptions = Object.entries(localizedOptions).map(
      ([key, value]) => ({
        value: key,
        label: value,
      }),
    );

    const handleOldAnswerValues = (answers: [Answer]) => {
      const oldAnswerValueByType = (type: QuestionType) =>
        answers?.find((answer) => answer.type === type)?.value;

      const oldSymptomValue = oldAnswerValueByType(QuestionType.ButtonOptions);
      const oldSliderValue = oldAnswerValueByType(QuestionType.Slider);
      const oldInputValue = oldAnswerValueByType(QuestionType.Text);
      const shouldShowDetailsForOldSymptomValue =
        oldSymptomValue && shouldShowDetails(oldSymptomValue, showSlider);

      if (!shouldShowDetailsForOldSymptomValue) {
        return setSymptomValue(() => undefined);
      }

      setSymptomValue(() => oldSymptomValue);
      setSliderValue(() => oldSliderValue && parseInt(oldSliderValue));
      setInputValue(() => oldInputValue);
    };

    useEffect(() => {
      if (!answer) {
        setSymptomValue(() => undefined);
        setSliderValue(() => undefined);
        setInputValue(() => undefined);
        return;
      }
      handleOldAnswerValues(answer as [Answer]);
    }, [answer]);

    const handleInputChange = (e: any) => {
      const textValue = e.target.value;
      setInputValue(textValue);
    };

    const renderDetails = () => {
      return (
        <Stack align="center" pt={8} width="100%">
          {showSlider && symptomValue === 'yes' && (
            <>
              <Slider
                options={sliderOptions}
                label={t('healthIssueFlow.question.symptomSpanLabel')}
                currentOption={sliderOptions.find(
                  (option) => option.value === sliderValue,
                )}
                onChange={(value) => setSliderValue(value)}
              />
            </>
          )}

          {!showInput && (
            <Button
              leftIcon={<BiPlusCircle size="1.5rem" />}
              variant="ghost"
              onClick={() => setInputVisibility(true)}
            >
              {t('healthIssueFlow.question.additionalInfoButtonLabel')}
            </Button>
          )}

          {description && !showSlider && (
            <>
              <ParagraphStack content={description as [string]} center />
              <br />
            </>
          )}

          {showInput && (
            <FormControl
              id="additionalInfo"
              aria-label={t(
                'healthIssueFlow.question.additionalInfoTextarea.label',
              )}
            >
              <FormLabel textAlign="center">
                {t('healthIssueFlow.question.additionalInfoTextarea.label')}
              </FormLabel>

              <Textarea
                value={inputValue}
                onChange={handleInputChange}
                placeholder={t(
                  'healthIssueFlow.question.additionalInfoTextarea.placeholder',
                )}
              />
            </FormControl>
          )}
        </Stack>
      );
    };

    return (
      <>
        <CardContentStack>
          <Heading as="h1" mb={[4, null, 6]} textStyle="cardHeading">
            {title}
          </Heading>

          {description && showSlider && (
            <ParagraphStack content={description as [string]} center />
          )}

          {responseOptions && title && (
            <ButtonOptions
              id="hasSymptomOptions"
              name={name}
              legend={title}
              options={responseOptions}
              currentValue={symptomValue}
              onChange={setSymptomValue}
              onReset={() => setSymptomValue(undefined)}
              setAnswerOptions={['no', 'unsure']}
            />
          )}

          {showDetails && renderDetails()}

          {!showDetails && <FastlaneButton />}
        </CardContentStack>

        {showDetails && <FlowItemSubmit variables={variables} />}
      </>
    );
  },
);

export default HealthIssueQuestion;
