import React from 'react';
import { Icon, Box, VStack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { CgCheck } from 'react-icons/cg';

// import AttachmentLinkIcon from './AttachmendLinkIcon';
import { ChatUsertype, IMessage } from '~types';
import { formatTime } from '~utils';

const StatusCircle: React.FC<{ online: boolean }> = ({ online }) => (
  <Box
    aria-hidden
    as="span"
    backgroundColor={online ? 'green.400' : 'gray.300'}
    borderRadius="full"
    display="inline-block"
    height=".7rem"
    marginRight=".5rem"
    marginBottom="-.05rem"
    width=".7rem"
  />
);

const ChatMessage: React.FC<
  IMessage & { currentUsertype: ChatUsertype; otherUserIsOnline: boolean }
> = ({
  username,
  usertype,
  seen,
  time,
  text,
  currentUsertype,
  otherUserIsOnline,
}) => {
  const { t } = useTranslation();
  const messageUsertype = usertype ?? ChatUsertype.Client;
  const isReceivedMessage = messageUsertype !== currentUsertype;
  const alignment = isReceivedMessage ? 'flex-start' : 'flex-end';
  const messageStatus =
    !isReceivedMessage && seen ? (
      <Icon
        as={CgCheck}
        color="primary.400"
        w={7}
        h={7}
        marginRight="-.3rem"
        marginTop="-.1rem"
      />
    ) : (
      <>
        <strong>{t('chat.discussion.messageUnreadText')}</strong>
        {' -'}
      </>
    );

  const messageBox =
    messageUsertype === ChatUsertype.Professional ? (
      <Text color="primary.500" fontWeight="bold" width="auto">
        {text}
      </Text>
    ) : (
      <Box
        background="#ebebeb"
        borderRadius="1.5rem"
        fontWeight="bold"
        paddingY={4}
        paddingX={6}
        wordBreak="break-word"
      >
        {text}
      </Box>
    );

  return (
    <VStack
      alignSelf={alignment}
      alignItems={alignment}
      maxWidth={['90%', '80%']}
    >
      <Box alignSelf={alignment} marginBottom="-.1rem" fontSize="sm">
        {isReceivedMessage ? (
          <>
            <StatusCircle online={otherUserIsOnline} />
            <strong>{username}</strong>
            {' -'}
          </>
        ) : (
          messageStatus
        )}
        {time && ` ${formatTime(time)}`}
      </Box>

      {messageBox}

      {/* {attachmentUrl && (
        <Button
          as={Link}
          href={attachmentUrl}
          leftIcon={<AttachmentLinkIcon color="white" />}
          target="_new"
          style={{ marginTop: '.7rem' }}
          alignSelf="flex-start"
          minWidth="unset"
        >
          {t('chat.discussion.messageAttachmentButtonText')}
        </Button>
      )} */}
    </VStack>
  );
};

export default ChatMessage;
