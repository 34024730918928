import * as React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const EyeInfectionIcon: React.FC<IconProps> = ({ ...props }) => {
  return (
    <Icon {...props}>
      <g fill="none">
        <path
          d="M62.986 16.169c0-1.45-1.187-2.626-2.652-2.626s-2.652 1.176-2.652 2.626a7.009 7.009 0 01-1.525 4.727 1.331 1.331 0 01-2.002.03 30.3 30.3 0 00-2.416-2.38 1.304 1.304 0 01-.207-1.728 11.508 11.508 0 001.177-10.986 2.647 2.647 0 00-2.021-1.703 2.668 2.668 0 00-2.5.883 2.608 2.608 0 00-.478 2.585 6.374 6.374 0 01-.546 6.303 1.334 1.334 0 01-1.666.396 29.218 29.218 0 00-2.691-1.163 1.31 1.31 0 01-.815-1.55A11.6 11.6 0 0039.34.976a2.68 2.68 0 00-3.743-.388 2.618 2.618 0 00-.391 3.707 6.547 6.547 0 011.61 6.125 1.326 1.326 0 01-1.43.961c-1.19-.14-2.389-.21-3.588-.212h-.035a.573.573 0 01-.586-.55c-.155-4.094-2.47-7.807-6.1-9.777a2.674 2.674 0 00-2.693-.105 2.623 2.623 0 00-1.395 2.285 2.622 2.622 0 001.346 2.313 6.594 6.594 0 013.552 5.195 1.315 1.315 0 01-1.032 1.39c-.944.208-1.876.464-2.793.767a1.33 1.33 0 01-1.62-.696c-1.658-3.5-4.968-5.95-8.826-6.535-1.417-.251-2.778.663-3.066 2.06a2.626 2.626 0 002.005 3.085 6.455 6.455 0 015.182 3.785c.23.575.024 1.232-.496 1.576-.644.42-1.252.853-1.827 1.294a1.336 1.336 0 01-1.692-.055 11.604 11.604 0 00-9.356-2.66c-1.457.157-2.509 1.454-2.35 2.897.159 1.442 1.468 2.484 2.925 2.326a6.894 6.894 0 014.79.985c.299.205.5.522.557.877.053.358-.043.72-.266 1.006a27.153 27.153 0 00-1.893 2.753 4.36 4.36 0 000 4.396c5.382 8.937 15.167 14.355 25.674 14.216 10.508.138 20.294-5.28 25.68-14.216.482-.83.673-1.793.544-2.742-.061-.462.13-.922.503-1.208a11.51 11.51 0 004.456-9.662zM31.809 38.755c-8.112.131-15.748-3.783-20.323-10.418a1.303 1.303 0 010-1.487c4.573-6.637 12.21-10.552 20.323-10.418 8.113-.133 15.751 3.782 20.327 10.418.31.448.31 1.039 0 1.487C47.558 34.969 39.92 38.88 31.81 38.745v.01z"
          fill="#F0F7FE"
        />
        <path
          d="M32.495 18c-.144 0-.288 0-.43.014a1.357 1.357 0 00-1.199 1.815c.182.504.274 1.035.271 1.571a4.75 4.75 0 01-4.75 4.749 4.615 4.615 0 01-1.568-.272 1.357 1.357 0 00-1.819 1.2v.426C23 32.748 27.253 37 32.5 37s9.5-4.252 9.5-9.497-4.253-9.498-9.5-9.498L32.495 18z"
          fill="#F0F7FE"
        />
        <path
          d="M57.813 29.169a1.5 1.5 0 00-3 0 4.029 4.029 0 01-.863 2.7.75.75 0 01-1.133.016c-.43-.478-.885-.932-1.366-1.359a.75.75 0 01-.117-.987A6.627 6.627 0 0052 23.265a1.5 1.5 0 00-2.828 1.008 3.67 3.67 0 01-.308 3.6.75.75 0 01-.942.226 16.442 16.442 0 00-1.523-.665.75.75 0 01-.46-.885 6.672 6.672 0 00-1.5-6.058 1.505 1.505 0 00-2.339 1.896 3.765 3.765 0 01.91 3.498.75.75 0 01-.808.549c-.674-.08-1.351-.12-2.03-.122h-.019a.326.326 0 01-.331-.313 6.653 6.653 0 00-3.45-5.584 1.5 1.5 0 10-1.551 2.566 3.767 3.767 0 012.008 2.967.75.75 0 01-.584.793c-.533.12-1.06.266-1.579.439a.75.75 0 01-.916-.398 6.62 6.62 0 00-4.992-3.732 1.5 1.5 0 00-.6 2.938 3.654 3.654 0 012.93 2.162.75.75 0 01-.28.9c-.364.24-.708.488-1.034.74a.75.75 0 01-.957-.032 6.519 6.519 0 00-5.291-1.52 1.5 1.5 0 10.325 2.984 3.869 3.869 0 012.709.563.75.75 0 01.165 1.075 15.515 15.515 0 00-1.071 1.572 2.511 2.511 0 000 2.511 16.65 16.65 0 0014.521 8.12 16.659 16.659 0 0014.525-8.12c.273-.474.38-1.024.307-1.566a.75.75 0 01.285-.69 6.6 6.6 0 002.52-5.518zm-17.635 12.9a13.65 13.65 0 01-11.494-5.951.75.75 0 010-.849 13.64 13.64 0 0111.494-5.95 13.65 13.65 0 0111.498 5.95.75.75 0 010 .849 13.65 13.65 0 01-11.498 5.944v.007z"
          fill="#0047B6"
        />
        <path
          d="M40.18 30.438c-.08 0-.159 0-.237.007a.75.75 0 00-.663 1.003 2.625 2.625 0 01-2.475 3.494 2.55 2.55 0 01-.867-.15.75.75 0 00-1.005.663v.236a5.25 5.25 0 105.25-5.25l-.003-.003z"
          fill="#0047B6"
        />
      </g>
    </Icon>
  );
};
