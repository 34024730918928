import React, { useContext } from 'react';
import { Heading, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import SearchField from '~components/SearchField';
import ParagraphStack from '~components/ParagraphStack';
import { CardContentStack } from '~components/Card';
import { qAndARoot } from '~constants';
import QuickLinks from './QuickLinks';
import {
  FlowResponse,
  QuestionType,
  SelectQuestion,
  useAddAnswerMutation,
  Options,
} from '~api';
import { QAndAFlowPath } from '~types';
import { AppContext } from '~state';

const QAndASearch: React.FC<FlowResponse> = React.memo(({ id, flowItem }) => {
  if (!id || !flowItem) return null;

  const { dispatch } = useContext(AppContext);
  const { title, description, question } = flowItem;
  const { shortcuts } = question as SelectQuestion;
  const { t } = useTranslation();
  const history = useHistory();

  const [addAnswer] = useAddAnswerMutation({
    onCompleted(data) {
      dispatch({
        type: 'SET_CURRENT_FLOW_ITEM',
        payload: data.addAnswer,
      });

      history.push(
        `/${qAndARoot}/${QAndAFlowPath.Results}/${data.addAnswer?.id}`,
      );

      return dispatch({
        type: 'SET_APP_LOADING_STATE',
        payload: false,
      });
    },
  });

  const handleChange = (option: Options) => {
    const variables = {
      id: id,
      answer: [
        {
          name: flowItem.name,
          type: QuestionType.SelectWithShortcuts,
          value: option?.value,
        },
      ],
    };

    dispatch({
      type: 'SET_APP_LOADING_STATE',
      payload: true,
    });

    return addAnswer({ variables });
  };

  const noResultsAnswer = () => {
    const variables = {
      id: id,
      answer: {
        name: flowItem.name,
        type: QuestionType.SelectWithShortcuts,
        value: 'ei-loytynyt',
      },
    };

    dispatch({
      type: 'SET_APP_LOADING_STATE',
      payload: true,
    });

    return addAnswer({ variables });
  };

  const shortcutsElement = shortcuts && (
    <Stack width="100%" pt={8}>
      <Heading as="h2" size="sm" mb={4}>
        {t('qAndAFlow.search.FAQ')}
      </Heading>

      <QuickLinks
        shortcuts={shortcuts as [Options]}
        handleClick={handleChange}
      />
    </Stack>
  );

  return (
    <CardContentStack>
      <Heading as="h1" textAlign="center" size="md">
        {title ?? t('qAndAFlow.search.title')}
      </Heading>

      {description && (
        <ParagraphStack content={description as [string]} center />
      )}

      <SearchField
        handleChange={handleChange}
        handleNoResults={noResultsAnswer}
        showUntilOptionsVisible={shortcutsElement}
      />
    </CardContentStack>
  );
});

export default QAndASearch;
