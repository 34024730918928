import * as React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const FluIcon: React.FC<IconProps> = ({ ...props }) => {
  return (
    <Icon {...props}>
      <g fill="none">
        <path
          d="M51.388 25.051a18.413 18.413 0 01-1.827-3.685C49.561 7.994 37.245 0 25.334 0A25.1 25.1 0 007.308 7.445 25.27 25.27 0 00.002 25.577c0 8.896 3.22 15.565 9.573 19.858.35.236.56.632.56 1.055v13.24c0 .701.567 1.27 1.267 1.27h25.331c.7 0 1.267-.569 1.267-1.27v-7.626c0-.702.567-1.27 1.267-1.27h3.8c3.497 0 6.332-2.845 6.332-6.355v-5.042c0-.702.567-1.271 1.267-1.271.474 0 .957 0 1.398-.025a3.151 3.151 0 002.44-1.322 3.114 3.114 0 00.48-1.889v-.35c.211-2.837-1.81-6.39-3.596-9.529zM25.605 38.156a2.705 2.705 0 01-2.685-2.72 2.705 2.705 0 012.705-2.699 2.705 2.705 0 012.696 2.71 2.715 2.715 0 01-2.716 2.709zm1.45-15.235a2.725 2.725 0 011.664-2.53 2.709 2.709 0 012.965.58 2.731 2.731 0 01.595 2.973 2.715 2.715 0 01-5.225-1.023zm6.877 22.194a2.705 2.705 0 01-2.688-2.719 2.705 2.705 0 012.706-2.7 2.705 2.705 0 012.695 2.712 2.715 2.715 0 01-2.713 2.707zm0-11.135a2.705 2.705 0 01-2.688-2.715 2.705 2.705 0 012.7-2.704 2.705 2.705 0 012.7 2.702c0 .721-.285 1.413-.794 1.923a2.703 2.703 0 01-1.918.794zm2.832-16.658a2.725 2.725 0 011.667-2.526 2.709 2.709 0 012.964.582 2.731 2.731 0 01.594 2.971 2.716 2.716 0 01-5.225-1.027zm1.388 9.745a2.725 2.725 0 011.666-2.529 2.709 2.709 0 012.965.582 2.731 2.731 0 01.594 2.972 2.715 2.715 0 01-2.512 1.684 2.706 2.706 0 01-1.92-.79 2.724 2.724 0 01-.8-1.92h.007zm4.101 13.854a2.705 2.705 0 01-2.688-2.718 2.705 2.705 0 012.705-2.7 2.705 2.705 0 012.696 2.709 2.715 2.715 0 01-2.713 2.71z"
          fill="#F0F7FE"
        />
        <path
          d="M47.285 27.024a12.497 12.497 0 01-1.246-2.505C46.04 15.432 37.641 10 29.52 10a17.143 17.143 0 00-17.272 17.382c0 6.045 2.195 10.577 6.527 13.495.239.16.382.429.382.717v8.997c0 .477.387.864.864.864h17.272a.864.864 0 00.864-.864v-5.182c0-.477.387-.864.864-.864h2.59a4.318 4.318 0 004.319-4.318V36.8c0-.477.386-.863.863-.863.323 0 .653 0 .954-.018a2.15 2.15 0 001.663-.898 2.11 2.11 0 00.328-1.283V33.5c.144-1.928-1.235-4.343-2.452-6.476zm-17.58 8.906a1.841 1.841 0 111.851-1.841 1.848 1.848 0 01-1.851 1.84zm.988-10.353a1.852 1.852 0 113.703-.025 1.852 1.852 0 01-3.703.025zm4.69 15.082a1.841 1.841 0 111.849-1.84 1.848 1.848 0 01-1.85 1.84zm0-7.567a1.841 1.841 0 11.016-3.683 1.841 1.841 0 01-.017 3.683zm1.93-11.32a1.852 1.852 0 113.703-.018 1.852 1.852 0 01-3.703.017zm.947 6.622a1.852 1.852 0 111.85 1.841 1.848 1.848 0 01-1.855-1.841h.005zm2.796 9.415a1.841 1.841 0 111.85-1.841 1.848 1.848 0 01-1.85 1.841z"
          fill="#0047B6"
        />
      </g>
    </Icon>
  );
};
