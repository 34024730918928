import { Center, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AvatarPlaceholderIcon from '~components/Chat/AvatarPlaceholderIcon';

const WaitingForService: React.FC<{ messagesSent?: boolean }> = ({
  messagesSent = false,
}) => {
  const { t } = useTranslation();

  return (
    <Center flexDirection="column" paddingBottom={4}>
      <AvatarPlaceholderIcon color="primary.500" width="5rem" height="auto" />

      <Heading as="h2" size="sm" marginBottom={2} marginTop={4}>
        {t('chat.discussion.waitingForService.title')}
      </Heading>

      {!messagesSent && (
        <Text textAlign="center">
          {t('chat.discussion.waitingForService.paragraph')}
        </Text>
      )}
    </Center>
  );
};

export default WaitingForService;
