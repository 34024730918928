import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { gql, useApolloClient } from '@apollo/client';
import { Heading, Image } from '@chakra-ui/react';

import { Options } from '~api';
import {
  formatOptionLabel,
  handleDropdownIndicator,
  selectStyles,
  handleKeyboardShortcuts,
} from '~utils';
import collectionIcons from '~images/collection-icons';

const CONTENT_SEARCH = gql`
  query ContentSearch($query: String) {
    contentSearch(query: $query) {
      label
      value
      imageId
    }
  }
`;

export const SearchField: React.FC<{
  handleChange: (value: Options) => void;
  handleNoResults?: () => void;
  showUntilOptionsVisible: React.ReactElement | null | undefined;
}> = ({ handleChange, handleNoResults, showUntilOptionsVisible }) => {
  const { t } = useTranslation();
  const client = useApolloClient();

  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [selectOptionsVisible, setSelectOptionsVisible] = useState(false);

  const loadOptions = async (inputValue: string, callback: any) => {
    setIsLoadingOptions(true);

    const { data, loading } = await client.query({
      query: CONTENT_SEARCH,
      variables: { query: inputValue },
    });

    if (loading) return null;

    setIsLoadingOptions(false);

    return callback([...data.contentSearch]);
  };

  return (
    <>
      <AsyncCreatableSelect
        isLoading={isLoadingOptions}
        autoFocus
        cacheOptions
        captureMenuScroll={false}
        components={{
          DropdownIndicator: () => handleDropdownIndicator(showSearchIcon),
          IndicatorSeparator: () => null,
          GroupHeading,
        }}
        filterOption={undefined}
        formatCreateLabel={() => t('qAndAFlow.search.noResultsLabel')}
        formatOptionLabel={formatOptionLabel}
        loadOptions={loadOptions}
        loadingMessage={() => null}
        menuShouldScrollIntoView={false}
        noOptionsMessage={() =>
          selectOptionsVisible ? t('common.search.noResults') : null
        }
        onChange={(option) => {
          handleChange(option as Options);
        }}
        onCreateOption={() => handleNoResults && handleNoResults()}
        onInputChange={(val: string) => {
          const shouldShowOptions = val.length > 0;
          setSelectOptionsVisible(shouldShowOptions);
          setShowSearchIcon(!val);
        }}
        onKeyDown={handleKeyboardShortcuts}
        placeholder={t('common.search.placeholder')}
        styles={selectStyles()}
      />
      {!selectOptionsVisible && showUntilOptionsVisible}
    </>
  );
};

function GroupHeading({ data }: { data: { icon: string; label: string } }) {
  return (
    <>
      {data?.icon && (
        <Image src={collectionIcons[data.icon]} maxWidth="40px" m={3} />
      )}
      <Heading size="md" textAlign="left" m={3}>
        {data?.label}
      </Heading>
    </>
  );
}
