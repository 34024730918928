import type { IConfigurationPartial } from '~types';
import { Locale, Configuration } from '~types';
import { localizations } from '~localizations';
import HomeNeuvola from '~screens/Home/HomeNeuvola';
import colors from '~theme/foundations/colorsNeuvola';

const configuration: IConfigurationPartial = {
  colors,
  header: {
    showInfoToggle: false,
    appLogoWidth: '8rem',
  },
  translations: {
    supportedLanguages: [Locale.FI],
    resources: localizations[Configuration.Neuvola] ?? {},
  },
  homeScreen: HomeNeuvola,
  styles: {
    inputBorderColor: colors.brand['600'],
    searchBorderWidth: '4px',
  },
};

export default configuration;
