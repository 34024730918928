import * as React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const EarInfectionIcon: React.FC<IconProps> = ({ ...props }) => {
  return (
    <Icon {...props}>
      <g fill="none">
        <path
          d="M37.693 13.745c1.178-.587 1.646-1.992 1.045-3.14C35.712 4.752 29.85.789 23.165.075 15.392-.596 7.953 3.3 4.238 9.985c-.553 1.12-.11 2.464 1.008 3.06 1.118.597 2.52.236 3.19-.821 2.792-5.055 8.405-8.004 14.274-7.5 5.058.552 9.485 3.564 11.76 8 .29.552.791.97 1.396 1.16a2.455 2.455 0 001.83-.14h-.003zm-6.007 20.427a2.364 2.364 0 00-3.213-.862 12.758 12.758 0 00-6.463 10.566 8.853 8.853 0 01-3.69 6.846 8.53 8.53 0 01-7.415 1.267c-3.405-1.054-5.848-4.021-6.21-7.541a2.349 2.349 0 00-2.576-2.09 2.34 2.34 0 00-2.107 2.557c.533 5.442 4.341 10.019 9.627 11.569 1.2.338 2.44.512 3.688.516a13.288 13.288 0 007.748-2.484 13.513 13.513 0 005.65-10.423 8.012 8.012 0 014.097-6.734 2.325 2.325 0 00.864-3.187z"
          fill="#F0F7FE"
        />
        <path
          d="M10.95 20.96c.142.027.285.04.428.04 1.147 0 2.13-.83 2.336-1.973a6.043 6.043 0 012.515-4.136 5.894 5.894 0 014.71-.917 5.6 5.6 0 013.722 2.546 2.358 2.358 0 003.303.629 2.427 2.427 0 00.621-3.346 10.334 10.334 0 00-6.989-4.593 10.481 10.481 0 00-8.176 1.744 10.744 10.744 0 00-4.382 7.207 2.43 2.43 0 00.384 1.8c.359.525.909.884 1.529.998zM52 50.947a3.637 3.637 0 00-3.643-3.631h-.607c-.67 0-1.214-.542-1.214-1.21v-10.29A1.821 1.821 0 0044.714 34h-2.428a1.821 1.821 0 00-1.822 1.816v10.29c0 .668-.543 1.21-1.214 1.21h-.607A3.637 3.637 0 0035 50.947v4.842c0 .669.544 1.211 1.214 1.211h14.572c.67 0 1.214-.542 1.214-1.21v-4.843zM23 23.822v2.352c-.027 1.166.9 2.157 2.132 2.274l7.384 1.525a1.28 1.28 0 001.028-.227c.288-.219.456-.548.456-.897v-7.697c0-.35-.168-.68-.457-.898a1.279 1.279 0 00-1.03-.227l-7.168 1.493c-1.31.05-2.344 1.066-2.344 2.302zM52 21.4c0-1.325-1.045-2.4-2.333-2.4h-10.5c-.645 0-1.167.537-1.167 1.2v9.6c0 .663.522 1.2 1.167 1.2h10.5C50.955 31 52 29.925 52 28.6v-7.2z"
          fill="#F0F7FE"
        />
        <g fill="#0047B6">
          <path d="M36.868 29.835a1.5 1.5 0 00.654-2.017 12.435 12.435 0 00-9.75-6.767 12.249 12.249 0 00-11.85 6.369 1.5 1.5 0 002.628 1.439 9.23 9.23 0 018.937-4.82 9.405 9.405 0 017.363 5.14 1.5 1.5 0 002.02.656h-.002zm-3.668 12.5a1.5 1.5 0 00-2.047-.554 8.205 8.205 0 00-4.119 6.788 5.7 5.7 0 01-2.352 4.398 5.4 5.4 0 01-4.725.814A5.678 5.678 0 0116 48.936a1.5 1.5 0 00-2.985.3 8.619 8.619 0 006.135 7.433A8.715 8.715 0 0021.5 57a8.423 8.423 0 004.938-1.596 8.7 8.7 0 003.6-6.696 5.153 5.153 0 012.612-4.326 1.5 1.5 0 00.55-2.047z" />
          <path d="M19.697 34.364a1.5 1.5 0 001.745-1.204 3.76 3.76 0 014.561-3.151 3.537 3.537 0 012.351 1.587 1.5 1.5 0 002.478-1.693 6.531 6.531 0 00-4.413-2.864 6.685 6.685 0 00-7.929 5.58 1.5 1.5 0 001.207 1.745zM46 53.25A2.25 2.25 0 0043.75 51h-.375a.75.75 0 01-.75-.75v-6.375c0-.621-.504-1.124-1.125-1.125H40c-.621 0-1.124.504-1.125 1.125v6.375a.75.75 0 01-.75.75h-.375a2.25 2.25 0 00-2.25 2.25v3c0 .414.336.75.75.75h9a.75.75 0 00.75-.75v-3zM28 35.984v1.531a1.455 1.455 0 001.308 1.48l4.531.994a.75.75 0 00.911-.732v-5.012a.75.75 0 00-.912-.732l-4.4.972A1.5 1.5 0 0028 35.984zM46 34.5a1.5 1.5 0 00-1.5-1.5h-6.75a.75.75 0 00-.75.75v6c0 .414.336.75.75.75h6.75A1.5 1.5 0 0046 39v-4.5z" />
        </g>
      </g>
    </Icon>
  );
};
