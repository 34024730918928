import React, { useContext, useState } from 'react';
import { Button, Heading, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router';

import ParagraphStack from '~components/ParagraphStack';
import { CardContentStack } from '~components/Card';
import { FlowItem, QuestionType, useAddAnswerMutation } from '~api';
import { healthIssueRoot } from '~constants';
import { AppContext } from '~state';
import FastlaneButton from '../FastlaneButton';

const HealthIssueQuestion: React.FC<FlowItem> = React.memo(
  ({ title, name, description }) => {
    const { dispatch } = useContext(AppContext);
    const [symptomValue, setSymptomValue] = useState<any>();
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const history = useHistory();

    if (!title) return null;

    const localizedOptions = t('healthIssueFlow.question.responseOptions', {
      returnObjects: true,
    });

    const responseOptions = Object.entries(localizedOptions).map(
      ([key, value]) => ({
        value: key,
        label: value,
      }),
    );

    const [addAnswer, { loading }] = useAddAnswerMutation({
      onError() {
        dispatch({
          type: 'SET_APP_LOADING_STATE',
          payload: false,
        });
      },
      onCompleted(data) {
        dispatch({
          type: 'SET_CURRENT_FLOW_ITEM',
          payload: data.addAnswer,
        });

        history.push(
          `/${healthIssueRoot}/${data.addAnswer.flowItem?.name}/${data.addAnswer.id}`,
        );

        return dispatch({
          type: 'SET_APP_LOADING_STATE',
          payload: false,
        });
      },
    });

    const respond = (value: any) => {
      setSymptomValue(value);

      const variables = {
        id: id,
        answer: [
          {
            type: QuestionType.ButtonOptions,
            name: name,
            value: value,
          },
        ],
      };

      dispatch({
        type: 'SET_APP_LOADING_STATE',
        payload: true,
      });

      return addAnswer({ variables });
    };

    return (
      <>
        <CardContentStack>
          <Heading as="h1" mb={['4', null, '6']} textStyle="cardHeading">
            {title}
          </Heading>

          {description && (
            <ParagraphStack content={description as [string]} center />
          )}

          <Stack>
            {responseOptions.map(({ value, label }) => (
              <Button
                key={`response-button-${value}`}
                isLoading={loading && symptomValue === value}
                onClick={() => respond(value)}
                variant={value === 'unsure' ? 'outline' : 'solid'}
              >
                {label}
              </Button>
            ))}
          </Stack>

          <FastlaneButton />
        </CardContentStack>
      </>
    );
  },
);

export default HealthIssueQuestion;
