import { Box, Button, Heading, VStack } from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  YedQuestion,
  FlowResponse,
  useAddAnswerMutation,
  QuestionType,
  ExternalLink,
  InputType,
} from '~api';
import { CardContentStack } from '~components/Card';
import { richTextParser } from '~utils';
import { qAndARoot } from '~constants';
import { AppContext } from '~state';
import YedTextInput from './YedTextInput/YedTextInput';
import YedLinks from './YedLinks';
import YedOptions from './YedOptions';
import YedBackButton from './YedBackButton';

const YedQuestion: React.FC<
  FlowResponse & { showBackButton?: boolean }
> = React.memo(({ id, flowItem, showBackButton }) => {
  if (!id || !flowItem) return null;

  const history = useHistory();
  const { dispatch } = useContext(AppContext);
  const { description } = flowItem;
  const { input, buttons, externalLinks } = flowItem?.question as YedQuestion;
  const inputType = input?.type;
  const [textInputValue, setTextInputValue] = useState<string | undefined>('');
  const [buttonValue, setButtonValue] = useState<string | undefined>();
  const [optionValue, setOptionValue] = useState<string[] | undefined>();

  const hasDescription = description && description.length > 0;
  const htmlDescription: any =
    hasDescription && richTextParser(description as [string]);

  const [addAnswer, { loading }] = useAddAnswerMutation({
    onError() {
      dispatch({
        type: 'SET_APP_LOADING_STATE',
        payload: false,
      });
    },
    onCompleted(data) {
      dispatch({
        type: 'SET_CURRENT_FLOW_ITEM',
        payload: data.addAnswer,
      });

      history.push(
        `/${qAndARoot}/${data.addAnswer.flowItem?.name}/${data.addAnswer.id}`,
      );

      return dispatch({
        type: 'SET_APP_LOADING_STATE',
        payload: false,
      });
    },
  });

  const handleButtonClick = (value: string | undefined) => {
    setButtonValue(value);

    const answer = [
      {
        name: 'yed-button-option',
        type: QuestionType.ButtonOptions,
        value: value,
      },
    ];

    if (textInputValue) {
      answer.push({
        name: 'yed-input',
        type: QuestionType.Text,
        value: textInputValue,
      });
    } else if (optionValue) {
      inputType === InputType.Radio
        ? answer.push({
            name: 'yed-input',
            type: QuestionType.Radio,
            value: optionValue[0],
          })
        : optionValue.map((checkboxValue) =>
            answer.push({
              name: 'yed-input',
              type: QuestionType.Checkbox,
              value: checkboxValue,
            }),
          );
    }

    dispatch({
      type: 'SET_APP_LOADING_STATE',
      payload: true,
    });

    addAnswer({
      variables: {
        id: id,
        answer: answer,
      },
    });
  };

  const buttonStack = buttons?.map((button, i) => (
    <Button
      key={`yed-button-${i}`}
      isLoading={loading && buttonValue === button?.value}
      onClick={() => handleButtonClick(button?.value)}
      value={button?.value}
      width="100%"
    >
      {button?.text && richTextParser([button?.text])}
    </Button>
  ));

  const renderInput = () => {
    if (!input) return null;

    if (inputType === InputType.Text || inputType === InputType.Textarea) {
      return (
        <YedTextInput
          {...input}
          handleInputChange={setTextInputValue}
          inputValue={textInputValue}
        />
      );
    }

    if (inputType === InputType.Radio || inputType === InputType.Checkbox) {
      return (
        <YedOptions
          {...input}
          optionValue={optionValue}
          onChange={setOptionValue}
        />
      );
    }

    return null;
  };

  return (
    <>
      {showBackButton && <YedBackButton />}

      <CardContentStack
        sx={{
          '& .emoji': {
            height: '1.5rem',
            marginLeft: '.2rem',
            width: 'auto',
          },
          '& p .emoji': {
            display: 'inline-block',
          },
          '> *': {
            maxWidth: '27rem',
          },
        }}
        marginTop={[8, null, 'unset']}
      >
        {flowItem.title && (
          <Heading as="h1" textStyle="cardHeading" marginBottom={4}>
            {flowItem.title}
          </Heading>
        )}

        {hasDescription && (
          <VStack>
            {htmlDescription.map((x: any, i: number) => (
              <Box key={`result-item-${i}`}>{x}</Box>
            ))}
          </VStack>
        )}

        {renderInput()}

        {buttonStack && (
          <VStack spacing={2} paddingTop={4}>
            {buttonStack}
          </VStack>
        )}

        {externalLinks && <YedLinks links={externalLinks as [ExternalLink]} />}
      </CardContentStack>
    </>
  );
});

export default YedQuestion;
