import type { IConfigurationPartial } from '~types';
import { Configuration } from '~types';
import { localizations } from '~localizations';
import colors from '~theme/foundations/colorsEspoo';
import appLogo from '~images/logo-espoo.png';

const configuration: IConfigurationPartial = {
  colors,
  header: {
    appLogo,
    appLogoWidth: '4rem',
  },
  translations: {
    resources: localizations[Configuration.Espoo] ?? {},
  },
};

export default configuration;
