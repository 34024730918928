import React from 'react';
import { FluIcon } from './icons/FluIcon';
import { SkinIcon } from './icons/SkinIcon';
import { EarInfectionIcon } from './icons/EarInfectionIcon';
import { EyeInfectionIcon } from './icons/EyeInfectionIcon';
import { StomachAcheIcon } from './icons/StomachAcheIcon';
import { PainIcon } from './icons/PainIcon';

const componentMap: any = {
  fluIcon: FluIcon,
  skinIcon: SkinIcon,
  earInfectionIcon: EarInfectionIcon,
  eyeInfectionIcon: EyeInfectionIcon,
  stomachAcheIcon: StomachAcheIcon,
  painIcon: PainIcon,
};

const KuuraIcon: React.FC<{ iconId: string }> = ({ iconId }) => {
  const Component = componentMap[iconId];
  return (
    <Component viewBox="0 0 55 61" boxSize={['2.5rem', '3rem']} color="blue" />
  );
};

export default KuuraIcon;
