import { useTranslation } from 'react-i18next';

const setDurationOptions = () => {
  const { t } = useTranslation();

  const localizedOptions = t('symptomDurationOptions', {
    returnObjects: true,
  });

  const durationOptions = Object.entries(localizedOptions)
    .map(([key, value]) => ({
      value: parseInt(key),
      label: value,
    }))
    .reverse();

  return durationOptions;
};

export default setDurationOptions;
