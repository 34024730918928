import type { IConfigurationPartial } from '~types';
import { Configuration } from '~types';
import { localizations } from '~localizations';

const configuration: IConfigurationPartial = {
  translations: {
    resources: localizations[Configuration.Terapianavigaattori] ?? {},
  },
};

export default configuration;
