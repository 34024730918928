import * as React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const PainIcon: React.FC<IconProps> = ({ ...props }) => {
  return (
    <Icon {...props}>
      <g fill="none">
        <path
          d="M26.632 35.992c.309-.306.435-.748.333-1.168a1.25 1.25 0 00-.831-.894l-2.738-.884a1.208 1.208 0 01-.79-.805l-.69-2.346a1.253 1.253 0 00-.896-.856 1.27 1.27 0 00-1.202.325l-5.385 5.323a.61.61 0 01-.904-.048 14.985 14.985 0 00-.613-.72c-2.932-2.923-7.692-2.982-10.697-.133A7.264 7.264 0 000 38.96a7.262 7.262 0 002.154 5.2 7.337 7.337 0 005.348 2.13c.33-.006.647.12.88.35.234.23.363.545.357.87a7.263 7.263 0 002.166 5.305c2.924 2.895 7.663 2.917 10.615.05a7.273 7.273 0 002.213-5.182 7.271 7.271 0 00-2.162-5.203 3.065 3.065 0 00-.292-.24.602.602 0 01-.243-.437.595.595 0 01.177-.464l5.42-5.346zM54.03 17.561a7.692 7.692 0 00-.567-8.354c-1.82-2.41-4.875-3.454-7.737-2.643a1.159 1.159 0 01-.915-.121 1.216 1.216 0 01-.553-.758c-.716-2.96-3.106-5.177-6.044-5.61-2.939-.431-5.839 1.01-7.334 3.643-1.91 3.717-.614 8.319 2.933 10.415.177.104.524.268.832.41a.598.598 0 01.325.367.621.621 0 01-.052.495l-4.016 7.088c-.214.382-.22.85-.017 1.238.204.387.592.636 1.02.655l2.432.122c.372.018.715.215.925.531l1.59 2.408c.237.359.636.567 1.058.552a1.224 1.224 0 001.02-.625l4.018-7.096a.587.587 0 01.861-.192c.112.082.238.16.335.224a7.048 7.048 0 005.5.74 7.245 7.245 0 004.385-3.489zM8.5 23h5a2.5 2.5 0 100-5h-5a2.5 2.5 0 100 5zm5.601-8.659a2.28 2.28 0 003.247-.042 2.338 2.338 0 00-.041-3.28l-2.373-2.315a2.274 2.274 0 00-2.244-.623 2.303 2.303 0 00-1.625 1.685 2.33 2.33 0 00.675 2.252l2.361 2.323zM22.5 13a2.5 2.5 0 002.5-2.5v-5a2.5 2.5 0 10-5 0v5a2.5 2.5 0 002.5 2.5zm24 19h-5a2.5 2.5 0 100 5h5a2.5 2.5 0 100-5zm-5.506 7.752a2.305 2.305 0 00-2.235-.69 2.28 2.28 0 00-1.68 1.614 2.252 2.252 0 00.63 2.232l2.372 2.419c.883.884 2.322.9 3.224.033a2.25 2.25 0 00.062-3.191l-2.373-2.417zM32.5 42a2.5 2.5 0 00-2.5 2.5v5a2.5 2.5 0 105 0v-5a2.5 2.5 0 00-2.5-2.5z"
          fill="#F0F7FE"
        />
        <path
          d="M29.134 38.68a.82.82 0 00-.325-1.36l-1.782-.582a.792.792 0 01-.515-.53l-.45-1.546a.82.82 0 00-1.366-.35l-3.507 3.508a.396.396 0 01-.589-.032 9.843 9.843 0 00-.4-.475 4.968 4.968 0 00-6.966-.087 4.812 4.812 0 00-.043 6.834 4.75 4.75 0 003.484 1.402.792.792 0 01.806.805 4.813 4.813 0 001.41 3.494 4.908 4.908 0 006.913.033 4.818 4.818 0 00.033-6.841 2.001 2.001 0 00-.19-.159.397.397 0 01-.158-.288.394.394 0 01.116-.305l3.529-3.522zm18.42-12.477a4.89 4.89 0 00-5.541-7.146.792.792 0 01-.98-.571 4.867 4.867 0 00-8.929-1.278 5.075 5.075 0 001.957 6.767c.119.068.35.174.556.266a.394.394 0 01.182.56l-2.68 4.606a.826.826 0 00-.012.805.814.814 0 00.681.426l1.623.079c.249.011.478.14.618.345l1.06 1.564a.82.82 0 001.388-.047l2.682-4.61a.4.4 0 01.575-.125c.074.053.158.104.223.145a4.815 4.815 0 006.597-1.786zm-30.221 3.63H20.5a1.583 1.583 0 000-3.166h-3.167a1.583 1.583 0 000 3.166zm3.642-5.245a1.588 1.588 0 002.217-2.274l-1.64-1.583a1.583 1.583 0 10-2.21 2.267l1.633 1.59zm5.858-1.088c.875 0 1.584-.709 1.584-1.583V18.75a1.583 1.583 0 00-3.167 0v3.167c0 .874.709 1.583 1.583 1.583zm15.834 12.667H39.5a1.583 1.583 0 100 3.166h3.167a1.583 1.583 0 100-3.166zm-3.615 5.182a1.583 1.583 0 10-2.27 2.204l1.638 1.69a1.583 1.583 0 002.272-2.206l-1.64-1.688zM33.167 42.5c-.875 0-1.584.709-1.584 1.583v3.167a1.583 1.583 0 103.167 0v-3.167c0-.874-.709-1.583-1.583-1.583z"
          fill="#0047B6"
        />
      </g>
    </Icon>
  );
};
