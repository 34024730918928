import React from 'react';
import { Redirect, useParams } from 'react-router-dom';

import { useStartQuestionQuery } from '~api';
import { qAndARoot } from '~constants';
import ErrorCard from '~components/ErrorCard';
import Container from '~components/Container';

const ShortQuestionRedirect: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const { data, loading, error } = useStartQuestionQuery({
    variables: { questionName: id },
  });

  if (loading) return null;

  return (
    <Container maxWidth="xl" px={[4, 8, 16]} py={4}>
      {error ? (
        <ErrorCard />
      ) : (
        <Redirect
          to={`/${qAndARoot}/${data?.startQuestion.flowItem?.name}/${data?.startQuestion.id}`}
        />
      )}
    </Container>
  );
};

export default ShortQuestionRedirect;
