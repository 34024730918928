import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { SlideFade } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import Container from '~components/Container';
import { AppContext } from '~state';
import QAndASearch from '~components/QAndAFlow/QAndASearch';
import QAndAResults from '~components/QAndAFlow/QAndAResults';
import { FlowItemType, FlowResponse, useGetFlowItemQuery } from '~api';
import ErrorCard from '~components/ErrorCard';
import Card from '~components/Card';
import YedQuestion from '~components/QAndAFlow/YedQuestion';
import ProgressBar from '~components/ProgressBar';
import './ExternalContent.scss';

const QuestionAndAnswer: React.FC = () => {
  const history = useHistory();
  const { state, dispatch } = useContext(AppContext);
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const dataInState = state?.currentFlowItem?.id === id;
  const [progressValue, setProgressValue] = useState<number | undefined>();

  const { data, error } = useGetFlowItemQuery({
    variables: { id },
    fetchPolicy: 'cache-and-network',
    skip: state.appIsLoading || dataInState,
    onCompleted: () => {
      dispatch({
        type: 'SET_APP_LOADING_STATE',
        payload: false,
      });
    },
  });

  const itemData = dataInState ? state.currentFlowItem : data?.flowItem;
  const flowResponse = { ...(itemData as FlowResponse) };
  const itemType = flowResponse?.flowItem?.type;
  const showErrorCard =
    !id ||
    error ||
    flowResponse.success === false ||
    (flowResponse.success && !flowResponse.flowItem);
  const isSlide =
    history.action === 'PUSH' && itemType !== FlowItemType.QaSearch;
  const currentStep = flowResponse.flowItem?.currentStep;
  const totalSteps = flowResponse.flowItem?.totalSteps;
  const itemProgressValue =
    currentStep && totalSteps && 100 / (totalSteps / currentStep);
  // TODO: remove fixed false value, enable showing progress based on data when ready
  const showProgressBar = false; // progressValue && !showErrorCard;

  useEffect(() => {
    itemProgressValue && setProgressValue(itemProgressValue);
  }, [flowResponse]);

  const mapFlowItems = () => {
    switch (itemType) {
      case FlowItemType.QaSearch:
        return <QAndASearch {...flowResponse} />;

      case FlowItemType.QaResult:
        return <QAndAResults {...flowResponse} />;

      case FlowItemType.YedQuestion:
        return (
          <YedQuestion {...flowResponse} showBackButton={!showProgressBar} />
        );

      default:
        return null;
    }
  };

  if (itemType === FlowItemType.SessionClosed)
    return (
      <Container maxWidth="container.xl" px={[4, 8, 16]} py={4}>
        <ErrorCard
          title={t('errors.sessionClosed.title')}
          message={t('errors.sessionClosed.message')}
        />
      </Container>
    );

  return (
    <Container key={id} maxWidth="container.xl" px={[4, 8, 16]} py={4}>
      {showProgressBar && progressValue && (
        <ProgressBar value={progressValue} />
      )}

      {showErrorCard ? (
        <ErrorCard />
      ) : (
        <SlideFade in={!!itemType} offsetX={isSlide ? 300 : 0} offsetY={0}>
          <Card className="QAndA-card">{mapFlowItems()}</Card>
        </SlideFade>
      )}
    </Container>
  );
};

export default QuestionAndAnswer;
