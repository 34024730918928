import React, { useReducer } from 'react';
import { render } from 'react-dom';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import './i18n';
import { AppContext, initialState, reducer } from './state';
import App from '~components/App';
import { envs, setError } from '~utils';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const errors = { graphQLErrors, networkError };
  let showToastMessageForError = true;

  if (process.env.NODE_ENV === 'development') {
    /* eslint-disable no-console */
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );
      });

    if (networkError) console.log(`[Network error]: ${networkError}`);
    /* eslint-enable no-console */
  }

  graphQLErrors &&
    graphQLErrors.map(({ message }) => {
      if (
        (message && message.indexOf('FlowResponse.id') > 0) ||
        message?.indexOf('"$id" of required type "ID!" was not provided') > 0
      ) {
        showToastMessageForError = false;
      }
    });

  showToastMessageForError && setError(errors);
});

const httpLink = new HttpLink({
  uri: envs.GRAPHQL_API_URL,
});

const client = new ApolloClient({
  uri: envs.GRAPHQL_API_URL,
  cache: new InMemoryCache(),
  link: ApolloLink.from([errorLink, httpLink]),
});

const Root = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  return (
    <AppContext.Provider value={value}>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </AppContext.Provider>
  );
};

render(<Root />, document.getElementById('app'));
