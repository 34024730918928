import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { langCookie } from '~constants';
import { translations } from '~configurations';
import { Locale, LanguageResources } from '~types';

const detectorOptions = {
  order: ['cookie', 'localStorage', 'navigator'],
  lookupCookie: langCookie,
  lookupLocalStorage: 'i18nextLng',
  caches: ['localStorage', 'cookie'],
};

type i18nResources = {
  [key in Locale]?: {
    custom: LanguageResources;
    fallback: LanguageResources;
  };
};

// Merge custom and fallback resources
const resources: i18nResources = {};
for (const lng of Object.values(Locale)) {
  resources[lng] = {
    custom: translations.resources[lng] ?? {},
    fallback: translations.fallbackResources[lng],
  };
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: resources,
    defaultNS: 'custom',
    fallbackNS: 'fallback',
    detection: detectorOptions,
    supportedLngs: translations.supportedLanguages,
    fallbackLng: translations.fallbackLanguage,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
