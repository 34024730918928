import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, ButtonProps } from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';

import { healthIssueRoot } from '~constants';
import { useAddAnswerMutation, QuestionType } from '~api/index';
import { AppContext } from '~state';

const FastlaneButton: React.FC<ButtonProps> = ({ ...props }) => {
  const { dispatch } = useContext(AppContext);
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const variables = {
    id: id,
    answer: {
      name: 'fastlane',
      type: QuestionType.Special,
      value: null,
    },
  };

  const [addAnswer, { loading }] = useAddAnswerMutation({
    variables,
    onError() {
      dispatch({
        type: 'SET_APP_LOADING_STATE',
        payload: false,
      });
    },
    onCompleted(data) {
      dispatch({
        type: 'SET_CURRENT_FLOW_ITEM',
        payload: data.addAnswer,
      });

      history.push(
        `/${healthIssueRoot}/${data.addAnswer.flowItem?.name}/${data.addAnswer.id}`,
      );

      return dispatch({
        type: 'SET_APP_LOADING_STATE',
        payload: false,
      });
    },
  });

  return (
    <Box>
      <Button
        onClick={(e) => {
          e.preventDefault();

          dispatch({
            type: 'SET_APP_LOADING_STATE',
            payload: true,
          });

          addAnswer();
        }}
        variant="link"
        disabled={loading}
        display="block"
        marginTop={8}
        whiteSpace="normal"
        {...props}
      >
        {t('healthIssueFlow.fastlane.buttonLabel')}
      </Button>
    </Box>
  );
};

export default FastlaneButton;
