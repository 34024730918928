import React from 'react';
import { Link, VStack } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

import { ExternalLink } from '~api';

const YedLinks: React.FC<{ links: [ExternalLink] }> = ({ links }) => {
  const linkStack = links?.map((link, i) => {
    if (!link) return null;

    const { url, text, newWindow } = link;

    if (newWindow) {
      return (
        <Link key={`yed-link-${i}`} href={url} isExternal>
          {text}
        </Link>
      );
    }

    return (
      <Link key={`yed-link-${i}`} as={RouterLink} to={url}>
        {text}
      </Link>
    );
  });

  return (
    <VStack spacing={2} paddingTop={4}>
      {linkStack}
    </VStack>
  );
};

export default YedLinks;
