import React from 'react';
import { Box, Flex, Image, VisuallyHidden } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import LanguageSwitcher from '~components/LanguageSwitcher/LanguageSwitcher';
import { header } from '~configurations';
import InfoToggle from '~components/InfoToggle';
import { qAndARoot } from '~constants';

const Header: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const pagesWithHeaderShownOnMobile = ['/', '/chat'];

  const headerDisplay =
    pagesWithHeaderShownOnMobile.includes(location.pathname) ||
    location.pathname.startsWith(`/${qAndARoot}/`)
      ? 'flex'
      : ['none', null, 'flex'];

  return (
    <Flex
      as="header"
      alignItems="center"
      p={4}
      height="4.7rem"
      display={headerDisplay}
    >
      <LanguageSwitcher />

      <Box position="relative" margin="0 auto">
        <Link to="/">
          <VisuallyHidden>{t('common.app_name')}</VisuallyHidden>

          <Image
            src={header.appLogo}
            width={header.appLogoWidth}
            height="auto"
            alt={`${t('common.app_organization')}`}
          />
        </Link>
      </Box>

      {header.showInfoToggle && <InfoToggle />}
    </Flex>
  );
};

export default Header;
