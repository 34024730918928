import React from 'react';
import { IState, IStateContext, Reducer } from './types';

export const initialState: IState = {
  modalIsOpen: false,
  appIsLoading: false,
  currentFlowItem: undefined,
};

export const reducer: Reducer = (state: IState, action): IState => {
  switch (action.type) {
    case 'TOGGLE_INFO_MODAL_STATE':
      return { ...state, modalIsOpen: !state.modalIsOpen };

    case 'SET_APP_LOADING_STATE':
      return { ...state, appIsLoading: action.payload };

    case 'SET_CURRENT_FLOW_ITEM':
      return { ...state, currentFlowItem: action.payload };
  }

  return state;
};

const stateContext: IStateContext = {
  state: initialState,
  dispatch: () => {},
};

export const AppContext = React.createContext(stateContext);
