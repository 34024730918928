import type { IConfigurationPartial } from '~types';
import { Configuration } from '~types';
import { localizations } from '~localizations';
import colors from '~theme/foundations/colorsHelsinki';
import appLogo from '~images/logo-helsinki.png';

const configuration: IConfigurationPartial = {
  colors,
  header: {
    appLogo,
    appLogoWidth: '8rem',
  },
  translations: {
    resources: localizations[Configuration.Helsinki] ?? {},
  },
};

export default configuration;
