const toBoolean = (value: string | undefined) => {
  if (typeof value !== 'string') return undefined;
  return value.toLowerCase() === 'true' || value === '1';
};

const envs: {
  GRAPHQL_API_URL: string;
  SERVER_URL: string;
  CONFIGURATION: string;
  FF_ENABLE_CHAT: boolean | undefined;
  FF_ENABLE_PRO_CHAT_VIEW: boolean | undefined;
} = {
  GRAPHQL_API_URL: process.env.GRAPHQL_API_URL || '',
  SERVER_URL: process.env.SERVER_URL || '',
  CONFIGURATION:
    process.env.CONFIGURATION || process.env.CLIENT_ORGANIZATION || '',
  FF_ENABLE_CHAT: toBoolean(process.env.FF_ENABLE_CHAT),
  FF_ENABLE_PRO_CHAT_VIEW: toBoolean(process.env.FF_ENABLE_PRO_CHAT_VIEW),
};

export default envs;
