import React, { useContext } from 'react';
import { Button, Heading, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import AuthenticationImage from '/images/svg/authentication.svg';
import AuthenticationMethodsImage from '/images/authentication-methods.png';
import { CardContentStack } from '~components/Card';
import { healthIssueRoot } from '~constants';
import { useAddAnswerMutation } from '~api';
import { AppContext } from '~state';

const HealthIssueAuthentication: React.FC<{ answerId: string }> = React.memo(
  ({ answerId }) => {
    const { dispatch } = useContext(AppContext);
    const { t } = useTranslation();
    const history = useHistory();

    const [addAnswer] = useAddAnswerMutation({
      variables: { id: answerId, answer: [] },
      onCompleted(data) {
        dispatch({
          type: 'SET_CURRENT_FLOW_ITEM',
          payload: data.addAnswer,
        });

        history.push(
          `/${healthIssueRoot}/${data.addAnswer.flowItem?.name}/${data.addAnswer.id}`,
        );

        return dispatch({
          type: 'SET_APP_LOADING_STATE',
          payload: false,
        });
      },
    });

    return (
      <CardContentStack mb={[4, null, 8]}>
        <Image
          src={AuthenticationImage}
          alt=""
          maxWidth={['170px', null, '230px']}
        />

        <Heading as="h1" textStyle="cardHeading">
          {t('healthIssueFlow.authentication.title')}
        </Heading>

        <Text textAlign="center">
          {t('healthIssueFlow.authentication.paragraph')}
        </Text>

        <Button
          onClick={() => {
            if (!answerId) return false;

            dispatch({
              type: 'SET_APP_LOADING_STATE',
              payload: true,
            });

            return addAnswer();
          }}
          variant="unstyled"
          display="flex"
          height="auto"
          _focus={{ boxShadow: 'none' }}
        >
          <Image src={AuthenticationMethodsImage} maxWidth="250px" mt={4} />
        </Button>
      </CardContentStack>
    );
  },
);

export default HealthIssueAuthentication;
