import React, { useContext, useState } from 'react';
import { Stack, Button, Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import { healthIssueRoot, qAndARoot } from '~constants';
import { QAndAFlowPath } from '~types';
import {
  useStartFlowLazyQuery,
  useGetStartMenuQuery,
  MenuItem,
  Action,
  useStartQuestionLazyQuery,
} from '~api';
import { AppContext } from '~state';
import Loading from '~components/Loading';
import Card, { CardContentStack } from '~components/Card';
import { flowInit } from '~configurations';

const FlowInit: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { dispatch } = useContext(AppContext);
  const [currentAction, setCurrentAction] = useState<Action>();
  const [currentActionIndex, setCurrentActionIndex] = useState<number>();

  const { data, loading } = useGetStartMenuQuery({
    fetchPolicy: 'cache-first',
  });

  const [
    startQuestion,
    { loading: questionLoading },
  ] = useStartQuestionLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: ({ startQuestion }) => {
      dispatch({
        type: 'SET_CURRENT_FLOW_ITEM',
        payload: startQuestion,
      });

      history.push(
        `/${qAndARoot}/${startQuestion.flowItem?.name}/${startQuestion.id}`,
      );

      return dispatch({
        type: 'SET_APP_LOADING_STATE',
        payload: false,
      });
    },
  });

  const [initFlow, { loading: flowLoading }] = useStartFlowLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: ({ start }) => {
      dispatch({
        type: 'SET_CURRENT_FLOW_ITEM',
        payload: start,
      });

      if (currentAction === Action.Search) {
        history.push(`/${qAndARoot}/${QAndAFlowPath.Search}/${start.id}`);
      } else {
        history.push(`/${healthIssueRoot}/${start.flowItem?.name}/${start.id}`);
      }

      return dispatch({
        type: 'SET_APP_LOADING_STATE',
        payload: false,
      });
    },
  });

  const handleClick = (action: Action, actionParameter: string) => {
    setCurrentAction(action);

    if (action !== Action.Disabled) {
      dispatch({
        type: 'SET_APP_LOADING_STATE',
        payload: true,
      });
    }

    switch (action) {
      case Action.Disabled:
        return null;

      case Action.Url:
        history.push(actionParameter);
        break;

      case Action.Question:
        return startQuestion({ variables: { questionName: actionParameter } });

      case Action.Search:
        return initFlow({ variables: { name: actionParameter } });

      case Action.Symptom:
        return initFlow({ variables: { name: actionParameter } });
    }

    return dispatch({
      type: 'SET_APP_LOADING_STATE',
      payload: false,
    });
  };

  return (
    <Card shadow={flowInit.cardShadow}>
      <CardContentStack mt={0} pt={flowInit.cardStackPadding}>
        <Heading as="h2" textAlign="center" textStyle="cardHeading">
          {t('flowInit.title')}
        </Heading>

        {loading ? (
          <Loading />
        ) : (
          <Stack spacing="2" pt={6}>
            {data?.startMenu.map((menuItem, i) => {
              const {
                translationKey,
                label,
                action,
                actionParameter,
              } = menuItem as MenuItem;

              const param = actionParameter as string;
              const dataLoading = flowLoading || questionLoading;
              const isLoading = dataLoading && currentActionIndex === i;
              const itemKey = `init-button-${action}-${i}`;

              if (action === Action.Chat) {
                return (
                  <Button
                    key={itemKey}
                    as={Link}
                    isLoading={isLoading}
                    to="/chat"
                  >
                    {translationKey ? t(translationKey) : label}
                  </Button>
                );
              }

              return (
                <Button
                  key={itemKey}
                  onClick={() => {
                    setCurrentActionIndex(i);
                    return handleClick(action, param);
                  }}
                  isLoading={isLoading}
                  isDisabled={action === Action.Disabled}
                >
                  {translationKey ? t(translationKey) : label}
                </Button>
              );
            })}
          </Stack>
        )}
      </CardContentStack>
    </Card>
  );
};

export default FlowInit;
