import React from 'react';
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Divider,
  Flex,
  Text,
  Heading,
  VisuallyHidden,
} from '@chakra-ui/react';

import { generateKey } from '~utils';

interface FlowItemSliderProps {
  options: { value: number; label: string }[];
  currentOption?: { value: number; label: string };
  onChange: (option: number) => void;
  label: string;
  hideLabel?: boolean;
}

const FlowItemSlider: React.FC<FlowItemSliderProps> = ({
  options,
  label,
  onChange,
  currentOption,
  hideLabel,
}) => {
  const sliderSteps = options.length;

  const sliderHeading = (
    <Heading as="h3" size="sm" textAlign="center" mb={4} id="rangeSliderLabel">
      {label}
    </Heading>
  );

  return (
    <>
      {hideLabel ? (
        <VisuallyHidden>{sliderHeading}</VisuallyHidden>
      ) : (
        sliderHeading
      )}

      <Flex mb={12} p="4 0" justifyContent="center">
        <Slider
          aria-labelledby="rangeSliderLabel"
          aria-valuetext={currentOption?.label}
          orientation="vertical"
          minH={sliderSteps * 8}
          min={1}
          max={sliderSteps}
          value={currentOption?.value ?? -1}
          onChange={(value) => onChange(value)}
        >
          <SliderTrack
            width={6}
            borderRadius={25}
            bg="white"
            border="1px solid"
            borderColor="gray.300"
          >
            <SliderFilledTrack bg="secondary.500" />
            <Flex
              aria-hidden
              height="100%"
              justify="space-between"
              direction="column"
            >
              {options.map((_, i) => (
                <Divider key={generateKey('sliderDivider', i)} />
              ))}
            </Flex>
          </SliderTrack>
          <SliderThumb
            boxSize={7}
            bg="primary.500"
            visibility={currentOption ? 'visible' : 'hidden'}
          />
        </Slider>

        <Flex
          direction="column"
          justify="space-between"
          margin="-.65rem 0 -.65rem"
          paddingLeft="1rem"
          aria-hidden
        >
          {options.map((option, i) => (
            <Text
              key={generateKey(option.value.toString(), i)}
              as="button"
              textAlign="left"
              onClick={(e) => {
                e.preventDefault();
                onChange(option.value);
              }}
            >
              {option.label}
            </Text>
          ))}
        </Flex>
      </Flex>
    </>
  );
};

export default FlowItemSlider;
