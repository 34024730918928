import React from 'react';

import Discussion from '~components/Chat/Discussion';
import DiscussionHeader from '~components/Chat/DiscussionHeader';
import { mainContentId } from '~constants';
import useChatConnection from '~hooks/useChatConnection';

const ChatDiscussion: React.FC = () => {
  const chatConnection = useChatConnection();

  return (
    <>
      <DiscussionHeader {...chatConnection} />

      <main id={mainContentId}>
        <Discussion {...chatConnection} />
      </main>
    </>
  );
};

export default ChatDiscussion;
