import React from 'react';
import { Link, Stack } from '@chakra-ui/react';
import { generateKey } from '~utils';
import { Options } from '~api';

interface QuickLinkProps {
  shortcuts: [Options];
  handleClick: (value: Options) => void;
}

const QuickLinks: React.FC<QuickLinkProps> = ({ shortcuts, handleClick }) => {
  return (
    <Stack spacing={3}>
      {shortcuts?.map((option, i) => {
        if (!option) return null;
        return (
          <Link
            key={generateKey(option.value, i)}
            as="button"
            onClick={(e) => {
              e.preventDefault();
              return handleClick(option);
            }}
            color="primary.500"
            textAlign="left"
          >
            {option.label}
          </Link>
        );
      })}
    </Stack>
  );
};

export default QuickLinks;
