import React, { useContext } from 'react';
import { Button, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ItemToOverview, QuestionType, useAddAnswerMutation } from '~api';
import { healthIssueRoot } from '~constants';
import { AppContext } from '~state';

interface QuestionItemProps {
  id: string;
  item: ItemToOverview;
}

const QuestionItem: React.FC<QuestionItemProps> = ({
  id,
  item: { flowItemName, question, userSelection, userInput },
}) => {
  const { dispatch } = useContext(AppContext);
  const { t } = useTranslation();
  const history = useHistory();
  const isDuration = flowItemName === 'health-issue-duration';

  const [addAnswer] = useAddAnswerMutation({
    variables: {
      id: id,
      answer: [
        {
          name: 'overview',
          type: QuestionType.Text,
          value: flowItemName,
        },
      ],
    },
    onError() {
      dispatch({
        type: 'SET_APP_LOADING_STATE',
        payload: false,
      });
    },
    onCompleted(data) {
      dispatch({
        type: 'SET_CURRENT_FLOW_ITEM',
        payload: data.addAnswer,
      });

      history.push(
        `/${healthIssueRoot}/${data.addAnswer.flowItem?.name}/${data.addAnswer.id}`,
      );

      return dispatch({
        type: 'SET_APP_LOADING_STATE',
        payload: false,
      });
    },
  });

  const handleLabels = () => {
    switch (flowItemName) {
      case 'health-issue-duration':
        return t(`healthIssueFlow.healthIssueDuration.title`);

      case 'health-issue-info':
        return t(`healthIssueFlow.healthIssueInfo.title`);

      case 'fastlane':
        return t(`healthIssueFlow.fastlane.issueDescription.label`);

      default:
        return null;
    }
  };

  return (
    <div>
      <Text mb={1}>
        <strong>{question ? question : handleLabels()}</strong>
      </Text>
      <Button
        variant="link"
        onClick={() => {
          dispatch({
            type: 'SET_APP_LOADING_STATE',
            payload: false,
          });

          addAnswer();
        }}
        whiteSpace="normal"
        textAlign="left"
      >
        {userSelection && userSelection.length > 0 && isDuration
          ? t(`symptomDurationOptions.${userSelection}`)
          : userSelection
          ? t(`healthIssueFlow.question.responseOptions.${userSelection}`)
          : null}

        {userSelection && userInput ? ` - ${userInput}` : userInput}

        {!userSelection && !userInput && '-'}
      </Button>
    </div>
  );
};

export default QuestionItem;
