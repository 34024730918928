import React, { useState } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  FlexProps,
  Button,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import Card, { CardContentStack } from '~components/Card';
import { useHistory } from 'react-router';

const ChatInit: React.FC<FlexProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const [chatId, setChatId] = useState<string | undefined>('');
  const history = useHistory();

  return (
    <Card {...props}>
      <CardContentStack
        as="form"
        maxWidth="23rem"
        onSubmit={(e: React.FormEvent<HTMLDivElement>) => {
          e.preventDefault();
          return history.push(`/chat/${chatId}`);
        }}
      >
        <FormControl aria-label={t('chat.init.inputLabel')} id="chatId">
          <FormLabel textAlign="center">{t('chat.init.inputLabel')}</FormLabel>

          <Input
            type="text"
            value={chatId}
            onChange={(e) => setChatId(e.target.value)}
            placeholder={t('chat.init.inputPlaceholder')}
            textAlign="center"
          />
        </FormControl>

        <Button type="submit" isDisabled={!chatId} minW="20rem" width="100%">
          {t('chat.init.buttonLabel')}
        </Button>
      </CardContentStack>
    </Card>
  );
};

export default ChatInit;
