import {
  Center,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react';
import React from 'react';

import { InputField, InputType, Options } from '~api';

interface YedOptionProps {
  optionValue: string[] | undefined;
  onChange: (nextValue: any) => void;
}

const YedOptions: React.FC<InputField & YedOptionProps> = ({
  type,
  label,
  options,
  optionValue,
  onChange,
}) => {
  if (!options) return null;

  const isRadio = type === InputType.Radio;
  const inputOptions = options as Options[];

  const answerOptions = inputOptions.map(({ value, label }, i) => {
    const key = `yed-${type.toLowerCase()}-option-${i}`;

    if (isRadio) {
      return (
        <Radio key={key} value={value}>
          {label}
        </Radio>
      );
    }

    return (
      <Checkbox key={key} value={value}>
        {label}
      </Checkbox>
    );
  });

  const optionStack = <Stack spacing={4}>{answerOptions}</Stack>;

  const optionGroup = isRadio ? (
    <RadioGroup
      onChange={(value) => onChange([value])}
      value={optionValue && optionValue[0]}
    >
      {optionStack}
    </RadioGroup>
  ) : (
    <CheckboxGroup onChange={onChange} defaultValue={optionValue}>
      {optionStack}
    </CheckboxGroup>
  );

  return (
    <Center>
      <FormControl as="fieldset">
        <FormLabel as="legend">{label}</FormLabel>
        {optionGroup}
      </FormControl>
    </Center>
  );
};

export default YedOptions;
