import React, { useContext, useEffect, useState } from 'react';
import {
  Image,
  FormControl,
  FormLabel,
  Textarea,
  Button,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import FeedbackImage from '~images/svg/feedback.svg';
import { CardContentStack } from '~components/Card';
import FlowItemSubmit from '~components/HealthIssueFlow/HealthIssueItemSubmit';
import {
  Answer,
  Maybe,
  QuestionType,
  useAddAnswerMutation,
  useStartFlowLazyQuery,
} from '~api';
import RatingOptions from './RatingOptions';
import { qAndARoot, closeSessionAnswerObject } from '~constants';
import { FlowName, QAndAFlowPath } from '~types';
import { AppContext } from '~state';

interface FeedbackProps {
  answerId: string;
  oldAnswer: Maybe<Array<Maybe<Answer>>>;
  showInitQA?: boolean;
}

const Feedback: React.FC<FeedbackProps> = ({
  answerId,
  oldAnswer,
  showInitQA,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { dispatch } = useContext(AppContext);
  const [ratingValue, setRatingValue] = useState<number | undefined>();
  const [inputValue, setInputValue] = useState<string | undefined>('');
  const oldRatingValue = oldAnswer?.find(
    (answer) => answer?.type === QuestionType.Number,
  )?.value;
  const [initQA, setInitQA] = useState(false);

  const feedbackAnswer = [
    {
      name: 'feedback-rating',
      type: QuestionType.Number,
      value: ratingValue?.toString() ?? '',
    },
    {
      name: 'feedback',
      type: QuestionType.Text,
      value: inputValue,
    },
  ];

  const feedbackVariables = {
    id: answerId,
    answer: feedbackAnswer,
  };

  const [addAnswer, { loading }] = useAddAnswerMutation({
    onError() {
      dispatch({
        type: 'SET_APP_LOADING_STATE',
        payload: false,
      });
    },
    onCompleted: () => {
      if (initQA) return initFlow();
      return null;
    },
  });

  const [initFlow, { loading: initLoading }] = useStartFlowLazyQuery({
    fetchPolicy: 'no-cache',
    variables: { name: FlowName.QAndA },
    onError() {
      dispatch({
        type: 'SET_APP_LOADING_STATE',
        payload: false,
      });
    },
    onCompleted: ({ start }) => {
      dispatch({
        type: 'SET_CURRENT_FLOW_ITEM',
        payload: start,
      });

      history.push(`/${qAndARoot}/${QAndAFlowPath.Search}/${start.id}`);

      return dispatch({
        type: 'SET_APP_LOADING_STATE',
        payload: false,
      });
    },
  });

  useEffect(() => {
    if (!oldAnswer) return;

    oldRatingValue && setRatingValue(() => parseInt(oldRatingValue));
  }, [oldAnswer]);

  useEffect(() => {
    if (
      !ratingValue ||
      (oldRatingValue && ratingValue === parseInt(oldRatingValue))
    )
      return;

    addAnswer({ variables: feedbackVariables });
  }, [ratingValue]);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textValue = e.target.value;

    return setInputValue(textValue);
  };

  const textareaLabel =
    ratingValue && ratingValue < 4
      ? t('feedback.input.labels.lowValue')
      : t('feedback.input.labels.highValue');

  return (
    <>
      <CardContentStack marginBottom={!ratingValue ? 4 : undefined}>
        <Image
          src={FeedbackImage}
          alt=""
          maxWidth="90px"
          display={['none', null, 'block']}
        />

        <RatingOptions
          ratingValue={ratingValue}
          setRatingValue={setRatingValue}
        />

        {ratingValue && (
          <FormControl pt={8}>
            <FormLabel textAlign="center">{textareaLabel}</FormLabel>

            <Textarea
              value={inputValue}
              onChange={handleInputChange}
              placeholder={t('feedback.input.placeholder')}
            />
          </FormControl>
        )}
      </CardContentStack>

      {showInitQA && (
        <Button
          isLoading={loading || initLoading}
          onClick={() => {
            dispatch({
              type: 'SET_APP_LOADING_STATE',
              payload: true,
            });

            const allVariables = {
              id: answerId,
              answer: [...feedbackAnswer, closeSessionAnswerObject],
            };

            setInitQA(true);

            return addAnswer({ variables: allVariables });
          }}
          variant="link"
          borderRadius="full"
          marginBottom={8}
          padding={3}
        >
          {t('qAndAFlow.results.newQuestionButtonText')}
        </Button>
      )}

      <FlowItemSubmit
        variables={feedbackVariables}
        label={t('feedback.submitLabel')}
        closeSession
      />
    </>
  );
};

export default Feedback;
