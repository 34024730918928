import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { ChakraProvider, VisuallyHidden } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { healthIssueRoot, qAndARoot, mainContentId } from '~constants';
import theme from '~theme/theme';
import Header from '~components/Header';
import NoContent from '~screens/404';
import Chat from '~screens/Chat';
import ChatDiscussion from '~screens/ChatDiscussion';
import HealthIssue from '~screens/HealthIssue';
import Home from '~screens/Home';
import InfoModal from '~components/InfoModal';
import QAndA from '~screens/QAndA';
import ShortQuestionRedirect from '~components/ShortQuestionRedirect';
import { chat } from '~configurations';

const App = () => {
  const { t } = useTranslation();

  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <VisuallyHidden as="a" href={`#${mainContentId}`}>
          {t('common.skipToContentLinkText')}
        </VisuallyHidden>

        <Switch>
          {chat.enableChat && (
            <Route exact path={[`/chat/:chatId`, `/chat/:usertype/:chatId`]}>
              <ChatDiscussion />
            </Route>
          )}

          <Route>
            <Header />

            <main id={mainContentId}>
              <Switch>
                <Route
                  exact
                  path={[
                    `/${healthIssueRoot}/:name`,
                    `/${healthIssueRoot}/:name/:id`,
                  ]}
                  component={HealthIssue}
                />

                <Route
                  exact
                  path={`/${qAndARoot}/:name/:id`}
                  component={QAndA}
                />

                {chat.enableChat && (
                  <Route exact path={`/chat`} component={Chat} />
                )}

                <Route
                  exact
                  path={`/question/:id`}
                  component={ShortQuestionRedirect}
                />

                <Route exact path={`/`} component={Home} />

                <Route component={NoContent} />
              </Switch>
            </main>
          </Route>
        </Switch>
        <InfoModal />
      </BrowserRouter>
    </ChakraProvider>
  );
};

export default App;
