import React, { useContext, useEffect, useState } from 'react';
import {
  Heading,
  Image,
  NumberInput,
  NumberInputField,
  FormControl,
  FormLabel,
  FormErrorMessage,
  ButtonGroup,
  Button,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import ParagraphStack from '~components/ParagraphStack';
import StartImage from '~images/svg/symptom-inquiry.svg';
import { CardContentStack } from '~components/Card';
import {
  FlowResponse,
  Options,
  QuestionType,
  useAddAnswerMutation,
} from '~api';
import { healthIssueRoot } from '~constants';
import { AppContext } from '~state';
import { useHistory } from 'react-router';
import { generateKey } from '~utils';
import SelectedToggleIndicator from '~components/SelectedToggleIndicator';

const HealthIssueBasicInfo: React.FC<FlowResponse> = React.memo(
  ({ id, flowItem }) => {
    if (!id) return null;

    const { t } = useTranslation();
    const { dispatch } = useContext(AppContext);
    const history = useHistory();
    const [ageValue, setAgeValue] = useState<number | string | undefined>('');
    const [genderValue, setGenderValue] = useState<string | undefined>();
    const answers = flowItem?.answer;

    const maxAge = 120;
    const minAge = 0;

    const isValidAge = ageValue && ageValue >= minAge && ageValue <= maxAge;

    const paragraphContent = Array.from(
      t('healthIssueFlow.basicInfo.paragraphContent', {
        returnObjects: true,
      }),
    ) as [string];

    const genderOptions: [Options] = t(
      'healthIssueFlow.basicInfo.genderSelect.options',
      {
        returnObjects: true,
      },
    );

    const variables = {
      id: id,
      answer: [
        {
          name: 'age',
          type: QuestionType.Age,
          value: ageValue?.toString(),
        },
        {
          name: 'gender',
          type: QuestionType.Gender,
          value: genderValue ?? '',
        },
      ],
    };

    const [addAnswer, { loading }] = useAddAnswerMutation({
      variables,
      onError() {
        dispatch({
          type: 'SET_APP_LOADING_STATE',
          payload: false,
        });
      },
      onCompleted(data) {
        dispatch({
          type: 'SET_CURRENT_FLOW_ITEM',
          payload: data.addAnswer,
        });

        history.push(
          `/${healthIssueRoot}/${data.addAnswer.flowItem?.name}/${data.addAnswer.id}`,
        );

        return dispatch({
          type: 'SET_APP_LOADING_STATE',
          payload: false,
        });
      },
    });

    useEffect(() => {
      const oldAgeValue = answers?.find(
        (answer) => answer?.type === QuestionType.Age,
      )?.value;

      oldAgeValue && setAgeValue(parseInt(oldAgeValue));
    }, [answers]);

    useEffect(() => {
      if (!genderValue) return;

      dispatch({
        type: 'SET_APP_LOADING_STATE',
        payload: true,
      });

      addAnswer();
    }, [genderValue]);

    const genderCount: number = genderOptions.length;
    const isTwoOptions = genderCount === 2;

    return (
      <CardContentStack>
        <Heading as="h2" textStyle="cardHeading">
          {t('healthIssueFlow.basicInfo.title')}
        </Heading>

        <Image
          src={StartImage}
          alt=""
          display={['none', null, 'block']}
          maxWidth={[null, null, '200px', '250px']}
          py={[null, null, '4', '6']}
        />

        {paragraphContent && paragraphContent.length > 0 && (
          <ParagraphStack content={paragraphContent} center />
        )}

        <FormControl
          id="ageInput"
          isInvalid={!!ageValue && !isValidAge}
          isRequired
        >
          <FormLabel textAlign="center" mx="0">
            {t('healthIssueFlow.basicInfo.ageInput.label')}
          </FormLabel>

          <NumberInput
            aria-label={t('healthIssueFlow.basicInfo.ageInput.label')}
            value={ageValue}
            onChange={(value) => {
              const numValue = parseInt(value);
              const currentValue = isNaN(numValue) ? undefined : numValue;

              return setAgeValue(currentValue);
            }}
            type="number"
            min={minAge}
            max={maxAge}
            width="15rem"
            margin="0 auto"
            clampValueOnBlur={false}
          >
            <NumberInputField textAlign="center" />

            <FormErrorMessage justifyContent="center">
              {t('healthIssueFlow.basicInfo.ageInput.errorMessage')}
            </FormErrorMessage>
          </NumberInput>
        </FormControl>

        <VStack spacing={0}>
          <FormLabel textAlign="center" mx="0">
            {t('healthIssueFlow.basicInfo.genderSelect.label')}
          </FormLabel>

          <ButtonGroup
            spacing={isTwoOptions ? '3px' : undefined}
            alignItems="center"
          >
            {genderOptions.map(({ value, label }, i) => {
              const borderRadius = isTwoOptions
                ? i === 0
                  ? '9999px 0 0 9999px'
                  : '0 9999px 9999px 0'
                : undefined;

              return (
                <Button
                  key={generateKey(`gender_${value}`)}
                  onClick={() => setGenderValue(value)}
                  isDisabled={!isValidAge}
                  isLoading={loading && genderValue === value}
                  backgroundColor={
                    genderValue && genderValue !== value
                      ? 'gray.500'
                      : undefined
                  }
                  borderRadius={borderRadius}
                  width="7.5rem"
                  minWidth="unset"
                >
                  {label}
                </Button>
              );
            })}

            {genderValue && isTwoOptions && (
              <SelectedToggleIndicator
                selected={
                  genderValue === genderOptions[0].value ? 'left' : 'right'
                }
              />
            )}
          </ButtonGroup>
        </VStack>
      </CardContentStack>
    );
  },
);

export default HealthIssueBasicInfo;
