import React from 'react';
import { Box, Heading, VStack } from '@chakra-ui/react';

import { CardContentStack } from '~components/Card';
import Feedback from '~components/Feedback';
import { FlowResponse } from '~api';
import { richTextParser } from '~utils';

const QAndAResults: React.FC<FlowResponse> = ({ flowItem, id }) => {
  if (!id || !flowItem) return null;

  const { title, description, answer } = flowItem;
  const hasDescription = description && description.length > 0;

  const htmlDescription: any =
    hasDescription && richTextParser(description as [string]);

  return (
    <>
      <CardContentStack paddingBottom={[0, 0, 0]}>
        <Heading as="h1" textStyle="cardHeading" mb={6}>
          {title}
        </Heading>

        {hasDescription && (
          <VStack maxWidth="27rem">
            {htmlDescription.map((x: any, i: number) => (
              <Box key={`result-item-${i}`}>{x}</Box>
            ))}
          </VStack>
        )}
      </CardContentStack>

      <Feedback answerId={id} oldAnswer={answer ?? []} showInitQA />
    </>
  );
};

export default QAndAResults;
