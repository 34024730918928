import React, { useEffect, useState } from 'react';
import { Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { CardContentStack } from '~components/Card';
import HealthIssueItemSubmit from '~components/HealthIssueFlow/HealthIssueItemSubmit';
import Slider from '~components/formComponents/Slider';
import { FlowResponse, Answer, QuestionType } from '~api';
import { setDurationOptions } from '~utils';

const HealthIssueGeneralDetails: React.FC<FlowResponse> = React.memo(
  ({ id, flowItem }) => {
    if (!id) return null;

    const { t } = useTranslation();
    const [sliderValue, setSliderValue] = useState<number | null | undefined>();
    const sliderOptions = setDurationOptions();
    const answers = flowItem?.answer;

    if (!sliderOptions) return null;

    useEffect(() => {
      if (!answers) return;
      handleOldAnswerValues(answers as [Answer]);
    }, [answers]);

    const variables = {
      id: id,
      answer: [
        {
          type: QuestionType.Slider,
          name: 'issue-duration',
          value: (sliderValue?.toString() ?? '') as any,
        },
      ],
    };

    const handleOldAnswerValues = (answers: [Answer]) => {
      const oldAnswerValueByType = (type: QuestionType) =>
        answers?.find((answer) => answer.type === type)?.value;

      const oldSliderValue = oldAnswerValueByType(QuestionType.Slider);

      oldSliderValue && setSliderValue(() => parseInt(oldSliderValue));
    };

    return (
      <>
        <CardContentStack as="form">
          <Heading as="h1" textStyle="cardHeading" pb={8}>
            {t('healthIssueFlow.healthIssueDuration.title')}
          </Heading>

          <Slider
            options={sliderOptions}
            label={t(
              'healthIssueFlow.healthIssueDuration.durationOptionsLabel',
            )}
            currentOption={sliderOptions.find(
              (option) => option.value === sliderValue,
            )}
            onChange={(value) => setSliderValue(value)}
            hideLabel
          />
        </CardContentStack>

        <HealthIssueItemSubmit variables={variables} />
      </>
    );
  },
);

export default HealthIssueGeneralDetails;
