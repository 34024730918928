import { envs } from '~utils';
import type { IConfiguration, IConfigurationPartial } from '~types';

// Import all configurations
import defaultConf from './default';
import helsinki from './helsinki';
import espoo from './espoo';
import tupakkalakko from './tupakkalakko';
import terapianavigaattori from './terapianavigaattori';
import neuvola from './neuvola';

function getConfig(organization: string): IConfigurationPartial {
  switch (organization.toLowerCase()) {
    case 'helsinki':
      return helsinki;
    case 'espoo':
      return espoo;
    case 'tupakkalakko':
      return tupakkalakko;
    case 'terapianavigaattori':
      return terapianavigaattori;
    case 'neuvola':
      return neuvola;
    default:
      return {};
  }
}

const clientConf: IConfigurationPartial = getConfig(envs.CONFIGURATION);

/**
 * Get config value from clientConf in defined there, otherwise get it from
 * defaultConf. If config value is an object do the same check for the first
 * level of keys but not deeper than that.
 *
 * @param {keyof IConfiguration} prop - Config property
 * @returns Configuration value
 */
function getConfigValue(prop: keyof IConfiguration) {
  const configValue = clientConf[prop];
  if (prop in clientConf && typeof configValue === 'object')
    return { ...defaultConf[prop], ...configValue };

  if (prop in clientConf && configValue != undefined) return configValue;

  return defaultConf[prop];
}

/* eslint-disable prettier/prettier */
export const colors = getConfigValue('colors') as IConfiguration['colors'];
export const header = getConfigValue('header') as IConfiguration['header'];
export const flowInit = getConfigValue('flowInit') as IConfiguration['flowInit'];
export const translations = getConfigValue('translations') as IConfiguration['translations'];
export const chat = getConfigValue('chat') as IConfiguration['chat'];
export const homeScreen = getConfigValue('homeScreen') as IConfiguration['homeScreen'];
export const styles = getConfigValue('styles') as IConfiguration['styles'];
/* eslint-enable prettier/prettier */

// Override some configuration values from environment variables
if (typeof envs.FF_ENABLE_CHAT === 'boolean')
  chat.enableChat = envs.FF_ENABLE_CHAT;
if (typeof envs.FF_ENABLE_PRO_CHAT_VIEW === 'boolean')
  chat.enableProChatView = envs.FF_ENABLE_PRO_CHAT_VIEW;
