import React from 'react';

import Container from '~components/Container';
import Intro from '~components/Intro';
import FlowInit from '~components/FlowInit';

const Home = () => {
  return (
    <>
      <Intro />
      <Container>
        <FlowInit />
      </Container>
    </>
  );
};

export default Home;
