import { AnswerInput, QuestionType } from '~api';

export const langCookie = 'i18next';
export const mainContentId = 'maincontent';
export const healthIssueRoot = 'health-issue';
export const qAndARoot = 'q-and-a';
export const flowIndex = 'index';
export const closeSessionAnswerObject: AnswerInput = {
  name: 'closeSession',
  type: QuestionType.Special,
};
