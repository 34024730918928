import React, { useContext, useRef, useState } from 'react';
import { createFilter } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { SimpleGrid, Box, Text, VStack } from '@chakra-ui/react';
import { matchSorter } from 'match-sorter';
import { isMobileOnly } from 'react-device-detect';
import ReactDOM from 'react-dom';

import {
  formatOptionLabel,
  generateKey,
  handleDropdownIndicator,
  selectStyles,
  handleKeyboardShortcuts,
} from '~utils';
import KuuraIcon from '~components/KuuraIcon';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { healthIssueRoot } from '~constants';
import {
  Options,
  QuestionType,
  SelectQuestion,
  useAddAnswerMutation,
} from '~api';
import { AppContext } from '~state';
import FastlaneButton from '~components/HealthIssueFlow/FastlaneButton';

const shortcutStyles = {
  display: 'block',
  padding: '4',
  textAlign: 'center',
  _hover: {
    backgroundColor: 'gray.50',
  },
};

const SelectWithShortcuts: React.FC<SelectQuestion> = ({
  options,
  shortcuts,
}) => {
  const { dispatch } = useContext(AppContext);
  const { id } = useParams<{ id: string }>();
  const [selectedOption, setSelectedOption] = useState();
  const history = useHistory();
  const { t } = useTranslation();
  const [selectOptionsVisible, setSelectOptionsVisible] = useState(false);
  const [selectOptions, setSelectOptions] = useState(options);
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const selectInputRef = useRef<null | HTMLElement>(null);

  const [addAnswer] = useAddAnswerMutation({
    onError() {
      dispatch({
        type: 'SET_APP_LOADING_STATE',
        payload: false,
      });
    },
    onCompleted(data) {
      dispatch({
        type: 'SET_CURRENT_FLOW_ITEM',
        payload: data.addAnswer,
      });

      history.push(
        `/${healthIssueRoot}/${data.addAnswer.flowItem?.name}/${data.addAnswer.id}`,
      );

      return dispatch({
        type: 'SET_APP_LOADING_STATE',
        payload: false,
      });
    },
  });

  const handleChange = (option: any) => {
    setSelectedOption(option);

    const variables = {
      id: id,
      answer: [
        {
          type: QuestionType.SelectWithShortcuts,
          name: 'symptom-select',
          value: option.value,
        },
      ],
    };

    dispatch({
      type: 'SET_APP_LOADING_STATE',
      payload: true,
    });

    return addAnswer({ variables });
  };

  const useFastlane = () => {
    const variables = {
      id: id,
      answer: {
        name: 'fastlane',
        type: QuestionType.Special,
        value: null,
      },
    };

    dispatch({
      type: 'SET_APP_LOADING_STATE',
      payload: true,
    });

    return addAnswer({ variables });
  };

  const renderShortcuts = () => (
    <SimpleGrid columns={[2, null, 3]}>
      {shortcuts?.map((shortcut, i) => {
        const { value, label, imageId } = shortcut as Options;

        return (
          <Box
            key={generateKey(`shortcut_${value}_${label}`)}
            as="button"
            onClick={() => handleChange({ value: value, label: label })}
            borderRight={[i % 2 == 0 ? 'base' : 'none', null, 'none']}
            borderTop={[i > 1 ? 'base' : 'none', null, 'none']}
            sx={shortcutStyles}
          >
            {imageId && <KuuraIcon iconId={imageId} />}

            <Text as="span" display="block" mt={4}>
              {label}
            </Text>
          </Box>
        );
      })}
    </SimpleGrid>
  );

  const handleInputChange = (inputValue: string) => {
    const shouldShowOptions = inputValue.length > 0;

    if (isMobileOnly && shouldShowOptions) {
      handleScroll();
    }

    const sortedAndSlicedOptions = matchSorter(options, inputValue, {
      keys: ['label'],
    }).slice(0, 40);

    setSelectOptions(sortedAndSlicedOptions);
    setSelectOptionsVisible(shouldShowOptions);
    setShowSearchIcon(!inputValue);
  };

  const handleScroll = () => {
    const selectDomNode = ReactDOM.findDOMNode(selectInputRef.current);

    if (!window || !(selectDomNode instanceof Element)) return false;

    const selectTop = selectDomNode?.getBoundingClientRect()?.top;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const topTarget = selectTop + scrollTop - 16;

    return window.scrollTo({ top: topTarget, behavior: 'smooth' });
  };

  return (
    <VStack spacing={4} pt={6} width="100%" maxWidth="23rem">
      <CreatableSelect
        aria-label={t('healthIssueFlow.symptomSelect.select.ariaLabel')}
        captureMenuScroll={false}
        components={{
          DropdownIndicator: () => handleDropdownIndicator(showSearchIcon),
          IndicatorSeparator: () => null,
        }}
        filterOption={createFilter({ ignoreAccents: false })}
        formatCreateLabel={() =>
          t('healthIssueFlow.symptomSelect.select.customOptionText')
        }
        formatOptionLabel={formatOptionLabel}
        isClearable
        isValidNewOption={(value) => value.length > 0}
        menuShouldScrollIntoView={false}
        noOptionsMessage={() =>
          selectOptionsVisible ? t('common.search.noResults') : null
        }
        onChange={(option) => option && handleChange(option)}
        onCreateOption={useFastlane}
        onInputChange={(val) => handleInputChange(val)}
        onKeyDown={handleKeyboardShortcuts}
        options={selectOptionsVisible ? (selectOptions as [Options]) : []}
        placeholder={t('healthIssueFlow.symptomSelect.select.placeholder')}
        ref={selectInputRef as any}
        styles={selectStyles()}
        value={selectedOption}
      />

      {shortcuts && !selectOptionsVisible && (
        <>
          {renderShortcuts()}

          <FastlaneButton marginTop={[2, 6]} marginBottom={[2]} />
        </>
      )}
    </VStack>
  );
};

export default SelectWithShortcuts;
