import { Box, Button, Textarea } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AutoResizeTextarea from 'react-textarea-autosize';

import CameraIcon from './CameraIcon';
import SendIcon from './SendIcon';

const MessageInput: React.FC<{
  handleMessageSubmit: (message: string) => void;
  handleTyping: () => void;
}> = ({ handleMessageSubmit, handleTyping }) => {
  const [value, setValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const { t } = useTranslation();

  const messageButtonIcon = (size: number) =>
    isFocused ? (
      <SendIcon color="primary.500" fontSize={size} />
    ) : (
      <CameraIcon color="primary.500" fontSize={size} />
    );

  const handleInputChange = (event: any) => {
    const inputValue = event.target.value;

    setValue(inputValue);
  };

  return (
    <Box position="relative" marginTop={8}>
      <Textarea
        as={AutoResizeTextarea}
        minRows={1}
        onChange={handleInputChange}
        onBlur={() => setIsFocused(false)}
        onFocus={() => setIsFocused(true)}
        onKeyDown={(e: any) => {
          if (e.code === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            setValue('');

            return handleMessageSubmit(value);
          }

          return handleTyping();
        }}
        placeholder={t('chat.discussion.messageInputPlaceholder')}
        value={value}
        borderRadius="1.5rem"
        minHeight="unset"
        paddingLeft={6}
        paddingRight={14}
        paddingY={4}
      />

      <Button
        variant="link"
        onMouseDown={() => {
          setValue('');
          handleMessageSubmit(value);
        }}
        position="absolute"
        bottom={5}
        right={4}
        zIndex={1}
      >
        {messageButtonIcon(24)}
      </Button>
    </Box>
  );
};

export default MessageInput;
