import React, { useContext } from 'react';
import { Flex, FlexProps, VStack, StackProps } from '@chakra-ui/react';
import { AppContext } from '~state';
import { cardBorderRadius } from '~theme/constants';

type CardProps = FlexProps & {
  isLoading?: boolean;
};

export const CardContentStack: React.FC<StackProps> = ({
  children,
  ...rest
}) => (
  <VStack
    maxWidth="43.75rem"
    spacing={4}
    px={[6, null, 8]}
    pt={[6, 8, 12]}
    pb={[6, 10, 12]}
    width="100%"
    {...rest}
  >
    {children}
  </VStack>
);

const Card: React.FC<CardProps> = ({ isLoading, children, ...rest }) => {
  const { state } = useContext(AppContext);
  const cardIsLoading = state.appIsLoading || isLoading;

  return (
    <Flex
      aria-live="polite"
      alignItems="center"
      borderRadius={cardBorderRadius}
      flexDirection="column"
      overflow="hidden"
      shadow="base"
      position="relative"
      {...rest}
    >
      {cardIsLoading && (
        <Flex
          align="center"
          background="whiteAlpha.600"
          height="100%"
          justify="center"
          position="absolute"
          width="100%"
          zIndex="overlay"
        />
      )}
      {children}
    </Flex>
  );
};

export default Card;
