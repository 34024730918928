import React from 'react';
import { Heading, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import Feedback from '~components/Feedback';
import FlowCompletedImage from '~images/svg/flow-completed.svg';
import { CardContentStack } from '~components/Card';
import { FlowResponse } from '~api';

const HealthIssueFeedback: React.FC<FlowResponse> = ({ id, flowItem }) => {
  if (!id || !flowItem) return null;

  const { answer } = flowItem;
  const { t } = useTranslation();

  return (
    <>
      <CardContentStack paddingBottom={[0, 0, 0]} as="form">
        <Image
          src={FlowCompletedImage}
          alt=""
          maxWidth={['170px', null, '250px']}
        />

        <Heading as="h1" textStyle="cardHeading">
          {t('healthIssueFlow.confirm.title')}
        </Heading>

        <Text textAlign="center">{t('healthIssueFlow.confirm.paragraph')}</Text>
      </CardContentStack>

      <Feedback answerId={id} oldAnswer={answer ?? []} />
    </>
  );
};

export default HealthIssueFeedback;
