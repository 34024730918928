import React, { useContext } from 'react';
import {
  Box,
  Stack,
  useRadio,
  useRadioGroup,
  Button,
  FormControl,
  FormLabel,
  VisuallyHidden,
} from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';
import { RiCheckboxCircleFill } from 'react-icons/ri';

import { healthIssueRoot } from '~constants';
import { QuestionType } from '~api';
import { useAddAnswerMutation } from '~api/index';
import { AppContext } from '~state';

interface ButtonOptionsProps {
  id: string;
  name: string;
  legend: string;
  options: {
    value: string;
    label: string;
  }[];
  onChange: (value: string) => void;
  onReset: () => void;
  currentValue?: string;
  setAnswerOptions: string[];
}

const RadioOption = ({ ...props }) => {
  const { dispatch } = useContext(AppContext);
  const { getInputProps, getCheckboxProps } = useRadio(props);
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [addAnswer, { loading, error }] = useAddAnswerMutation({
    variables: {
      id,
      answer: [
        {
          name: props.name,
          type: QuestionType.ButtonOptions,
          value: props.value,
        },
      ],
    },
    onError() {
      dispatch({
        type: 'SET_APP_LOADING_STATE',
        payload: false,
      });
    },
    onCompleted(data) {
      dispatch({
        type: 'SET_CURRENT_FLOW_ITEM',
        payload: data.addAnswer,
      });

      history.push(
        `/${healthIssueRoot}/${data.addAnswer.flowItem?.name}/${data.addAnswer.id}`,
      );

      return dispatch({
        type: 'SET_APP_LOADING_STATE',
        payload: false,
      });
    },
  });

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  // TODO: proper error handling
  if (error) return null;

  return (
    <Button
      as="label"
      isLoading={loading}
      variant={props.value === 'unsure' ? 'outline' : 'solid'}
      cursor="pointer"
      textAlign="center"
    >
      <input
        onClick={
          props.setAnswerOptions.includes(props.value)
            ? () => {
                dispatch({
                  type: 'SET_APP_LOADING_STATE',
                  payload: true,
                });

                addAnswer();
              }
            : undefined
        }
        {...input}
      />

      <Box {...checkbox} aria-hidden={false} width="100%">
        {props.children}
      </Box>
    </Button>
  );
};

const ButtonOptions: React.FC<ButtonOptionsProps> = ({
  id,
  name,
  legend,
  options,
  onChange,
  onReset,
  currentValue,
  setAnswerOptions,
}) => {
  if (currentValue) {
    const currentOption = options.find(({ value }) => value === currentValue);

    if (
      currentOption?.value &&
      !setAnswerOptions.includes(currentOption?.value)
    )
      return (
        <Button
          onClick={onReset}
          cursor="pointer"
          variant="outline"
          border="none"
        >
          {currentOption?.label}
          <Box
            as={RiCheckboxCircleFill}
            aria-hidden
            color="primary.500"
            height="100%"
            top="0"
            right={4}
            position="absolute"
            size="2rem"
          />
        </Button>
      );
  }

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: name,
    defaultValue: undefined,
    onChange: onChange,
  });

  const group = getRootProps();

  return (
    <FormControl id={id} as="fieldset" aria-label={legend}>
      <VisuallyHidden>
        <FormLabel as="legend" textAlign="center" mx="0">
          {legend}
        </FormLabel>
      </VisuallyHidden>

      <Stack aria-label={legend} spacing={2} alignItems="center" {...group}>
        {options.map(({ value, label: optionLabel }, i) => {
          const radio = getRadioProps({ value });

          return (
            <RadioOption
              key={value}
              index={i}
              legend={legend}
              setAnswerOptions={setAnswerOptions}
              {...radio}
            >
              {optionLabel}
            </RadioOption>
          );
        })}
      </Stack>
    </FormControl>
  );
};

export default ButtonOptions;
