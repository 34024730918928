import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '~components/Modal';
import { AppContext } from '~state';
import ParagraphStack from '~components/ParagraphStack';

const InfoModal: React.FC = () => {
  const { dispatch, state } = useContext(AppContext);
  const { t } = useTranslation();

  const infoContent: [string] = t('infoModal.paragraphContent', {
    returnObjects: true,
  });

  return (
    <Modal
      modalIsOpen={state.modalIsOpen}
      onCloseModal={() =>
        dispatch({
          type: 'TOGGLE_INFO_MODAL_STATE',
          payload: !state.modalIsOpen,
        })
      }
      title={t('infoModal.title')}
    >
      <ParagraphStack content={infoContent} />
    </Modal>
  );
};

export default InfoModal;
