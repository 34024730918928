import React, { useEffect, useState } from 'react';
import { FormControl, FormLabel, Heading, Textarea } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { CardContentStack } from '~components/Card';
import HealthIssueItemSubmit from '~components/HealthIssueFlow/HealthIssueItemSubmit';
import { Answer, FlowResponse, QuestionType } from '~api';

const FastlaneIssueDescription: React.FC<FlowResponse> = ({ id, flowItem }) => {
  if (!id) return null;

  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string | null | undefined>();
  const answers = flowItem?.answer;

  useEffect(() => {
    if (!answers) return;
    handleOldAnswerValues(answers as [Answer]);
  }, [answers]);

  const handleOldAnswerValues = (answers: [Answer]) => {
    const oldAnswerValueByType = (type: QuestionType) =>
      answers?.find((answer) => answer.type === type)?.value;

    const oldInputValue = oldAnswerValueByType(QuestionType.Text);

    setInputValue(() => oldInputValue);
  };

  const variables = {
    id: id,
    answer: {
      name: 'fastlane-issue-description',
      type: QuestionType.Text,
      value: inputValue ?? '',
    },
  };

  return (
    <>
      <CardContentStack as="form">
        <Heading as="h1" textStyle="cardHeading">
          {t('healthIssueFlow.fastlane.title')}
        </Heading>

        <FormControl
          id="issue-description"
          aria-label={t('healthIssueFlow.fastlane.issueDescription.label')}
          paddingTop={4}
        >
          <FormLabel textAlign="center">
            {t('healthIssueFlow.fastlane.issueDescription.label')}
          </FormLabel>

          <Textarea
            value={inputValue as string}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={t(
              'healthIssueFlow.fastlane.issueDescription.placeholder',
            )}
            rows={6}
          />
        </FormControl>
      </CardContentStack>

      <HealthIssueItemSubmit variables={variables} />
    </>
  );
};

export default FastlaneIssueDescription;
