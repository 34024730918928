import React from 'react';

import Container from '~components/Container';
import ErrorCard from '~components/ErrorCard';

const Home = () => {
  return (
    <Container maxWidth="container.xl" px={[4, 8, 16]} py={4}>
      <ErrorCard />
    </Container>
  );
};

export default Home;
