import { extendTheme, ThemeOverride } from '@chakra-ui/react';

import Accordion from './components/accordion';
import Button from './components/button';
import FormLabel from './components/formLabel';
import Input from './components/input';
import Link from './components/link';
import NumberInput from './components/numberInput';
import Text from './components/text';
import Textarea from './components/textarea';
import Heading from './components/heading';

import { colors } from '~configurations';

const overrides: ThemeOverride = {
  borders: {
    base: '1px solid #E9F4FF',
  },
  colors,
  components: {
    Accordion,
    Button,
    FormLabel,
    Input,
    Link,
    NumberInput,
    Text,
    Textarea,
    Heading,
  },
  fonts: {
    heading: 'Quicksand, sans-serif',
    body: 'Quicksand, sans-serif',
  },
  textStyles: {
    cardHeading: {
      fontSize: ['1.375rem', null, '1.875rem'],
      lineHeight: '125%',
      textAlign: 'center',
    },
  },
  shadows: {
    base: '0 0 14px 0 rgba(0, 0, 0, 0.1)',
  },
  styles: {
    global: {
      body: {
        fontWeight: '500',
        marginBottom: '2rem', // Extra padding for scroll when submit is fixed
      },
      'strong, b': {
        fontWeight: '700',
      },
      'ul, ol': {
        marginTop: '.2rem',
        paddingLeft: '1.3rem',
        'li + li': {
          marginTop: '.1rem',
        },
      },
    },
  },
};

export default extendTheme(overrides);
