import React, { useContext, useEffect, useState } from 'react';
import { useParams, RouterProps } from 'react-router-dom';
import { SlideFade, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import Container from '~components/Container';
import HealthIssueAlarmingSymptom from '~components/HealthIssueFlow/HealthIssueAlarmingSymptom';
import FlowAuthentication from '~components/HealthIssueFlow/HealthIssueAuthentication';
import HealthIssueAuthSuccess from '~components/HealthIssueFlow/HealthIssueAuthSuccess';
import HealthIssueBasicInfo from '~components/HealthIssueFlow/HealthIssueBasicInfo';
import HealthIssueFeedback from '~components/HealthIssueFlow/HealthIssueFeedback';
import HealthIssueSymptomSelect from '~components/HealthIssueFlow/HealthIssueSymptomSelect';
import HealthIssueQuestion from '~components/HealthIssueFlow/HealthIssueQuestion';
import HealthIssueQuestionSimple from '~components/HealthIssueFlow/HealthIssueQuestionSimple';
import HealthIssueSymptomOverview from '~components/HealthIssueFlow/HealthIssueSymptomOverview';
import ProgressBar from '~components/ProgressBar';
import { AppContext } from '~state';
import Card from '~components/Card';
import {
  FlowItem,
  FlowItemType,
  FlowResponse,
  useGetFlowItemQuery,
} from '~api';
import HealthIssueItemSubmit from '~components/HealthIssueFlow/HealthIssueItemSubmit';
import ErrorCard from '~components/ErrorCard';
import HealthIssueInstructions from '~components/HealthIssueFlow/HealthIssueInstructions';
import FastlaneIssueDescription from '../../components/HealthIssueFlow/FastlaneIssueDescription/FastlaneIssueDescription';
import HealthIssueDuration from '~components/HealthIssueFlow/HealthIssueDuration';
import HealthIssueInfo from '~components/HealthIssueFlow/HealthIssueInfo';
import SymptomIntro from '~components/HealthIssueFlow/SymptomIntro';
import ThankYouForAnswering from '~components/HealthIssueFlow/ThankYouForAnswering';

const HealthIssue: React.FC<RouterProps> = React.memo(({ history }) => {
  const { state, dispatch } = useContext(AppContext);
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const [progressValue, setProgressValue] = useState<number | undefined>();
  const dataInState = state?.currentFlowItem?.id === id;

  const { data, error } = useGetFlowItemQuery({
    variables: { id },
    skip: state.appIsLoading || dataInState,
    fetchPolicy: 'cache-and-network',
    onCompleted() {
      dispatch({
        type: 'SET_APP_LOADING_STATE',
        payload: false,
      });
    },
  });

  const itemData = dataInState ? state.currentFlowItem : data?.flowItem;
  const flowResponse = { ...(itemData as FlowResponse) };
  const itemType = flowResponse?.flowItem?.type;
  const currentStep = flowResponse.flowItem?.currentStep;
  const totalSteps = flowResponse.flowItem?.totalSteps;
  const itemProgressValue =
    currentStep && totalSteps && 100 / (totalSteps / currentStep);
  const isSlide =
    history.action === 'PUSH' && itemType !== FlowItemType.Instructions;
  const showErrorCard =
    !id ||
    error ||
    flowResponse.success === false ||
    (flowResponse.success && !flowResponse.flowItem);

  useEffect(() => {
    itemProgressValue && setProgressValue(itemProgressValue);
  }, [flowResponse]);

  const mapFlowItems = () => {
    switch (itemType) {
      case FlowItemType.Instructions:
        return <HealthIssueInstructions answerId={id} />;

      case FlowItemType.BasicInfo:
        return <HealthIssueBasicInfo {...flowResponse} />;

      case FlowItemType.SymptomSelect:
        return (
          <HealthIssueSymptomSelect {...(flowResponse?.flowItem as FlowItem)} />
        );

      case FlowItemType.SymptomIntro:
        return <SymptomIntro answerId={id} />;

      case FlowItemType.SymptomQuestion:
        return (
          <HealthIssueQuestion
            {...(flowResponse?.flowItem as FlowItem)}
            showSlider
            showDetailsToggle
          />
        );

      case FlowItemType.SymptomQuestionSimple:
        return (
          <HealthIssueQuestionSimple
            {...(flowResponse?.flowItem as FlowItem)}
          />
        );

      case FlowItemType.SymptomQuestionSimpleWithDetails:
        return (
          <HealthIssueQuestion {...(flowResponse?.flowItem as FlowItem)} />
        );

      case FlowItemType.HealthIssueDuration:
        return <HealthIssueDuration {...flowResponse} />;

      case FlowItemType.HealthIssueInfo:
        return <HealthIssueInfo {...flowResponse} />;

      case FlowItemType.AlarmingSymptom:
        return <HealthIssueAlarmingSymptom answerId={id} />;

      case FlowItemType.SymptomOverview:
        return <HealthIssueSymptomOverview {...flowResponse} />;

      case FlowItemType.ThankForAnswers:
        return <ThankYouForAnswering answerId={id} />;

      case FlowItemType.Authentication:
        return <FlowAuthentication answerId={id} />;

      case FlowItemType.AuthenticationSuccess:
        return <HealthIssueAuthSuccess {...flowResponse} />;

      case FlowItemType.Feedback:
        return <HealthIssueFeedback {...flowResponse} />;

      case FlowItemType.FastlaneIssueDescription:
        return <FastlaneIssueDescription {...flowResponse} />;

      default:
        if (flowResponse?.flowItem?.isDebug) {
          return (
            <>
              <Text mt={4}>Debug: {flowResponse.flowItem.type}</Text>

              <HealthIssueItemSubmit
                variables={{
                  id: id,
                  answer: [],
                }}
                label="Ohita"
              />
            </>
          );
        }

        return;
    }
  };

  if (itemType === FlowItemType.SessionClosed)
    return (
      <Container maxWidth="container.xl" px={[4, 8, 16]} py={4}>
        <ErrorCard
          title={t('errors.sessionClosed.title')}
          message={t('errors.sessionClosed.message')}
        />
      </Container>
    );

  return (
    <Container
      key={id}
      maxWidth="container.xl"
      px={[4, 8, 16]}
      py={4}
      sx={{
        '.chakra-offset-slide > *': {
          overflow: 'visible',
        },
      }}
    >
      {progressValue && !showErrorCard && <ProgressBar value={progressValue} />}

      {showErrorCard ? (
        <ErrorCard />
      ) : (
        <SlideFade in={true} offsetX={isSlide ? 300 : 0} offsetY={0}>
          <Card>{mapFlowItems()}</Card>
        </SlideFade>
      )}
    </Container>
  );
});

export default HealthIssue;
