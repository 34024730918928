import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormLabel,
  Heading,
  Textarea,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { CardContentStack } from '~components/Card';
import HealthIssueItemSubmit from '~components/HealthIssueFlow/HealthIssueItemSubmit';
import { FlowResponse, Answer, QuestionType } from '~api';
import { setDurationOptions } from '~utils';

const HealthIssueGeneralDetails: React.FC<FlowResponse> = React.memo(
  ({ id, flowItem }) => {
    if (!id) return null;

    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState<string | null | undefined>();
    const sliderOptions = setDurationOptions();
    const answers = flowItem?.answer;

    if (!sliderOptions) return null;

    useEffect(() => {
      if (!answers) return;
      handleOldAnswerValues(answers as [Answer]);
    }, [answers]);

    const variables = {
      id: id,
      answer: [
        {
          type: QuestionType.Text,
          name: 'issue-details',
          value: inputValue ?? '',
        },
      ],
    };

    const handleOldAnswerValues = (answers: [Answer]) => {
      const oldAnswerValueByType = (type: QuestionType) =>
        answers?.find((answer) => answer.type === type)?.value;

      const oldInputValue = oldAnswerValueByType(QuestionType.Text);

      setInputValue(() => oldInputValue);
    };

    return (
      <>
        <CardContentStack as="form">
          <Heading as="h1" textStyle="cardHeading" marginBottom={4}>
            {t('healthIssueFlow.healthIssueInfo.title')}
          </Heading>

          <Text textAlign="center" marginBottom={4}>
            {t('healthIssueFlow.healthIssueInfo.description')}
          </Text>

          <FormControl
            id="additionalInfo"
            aria-label={t('healthIssueFlow.healthIssueInfo.textarea.label')}
          >
            <FormLabel textAlign="center">
              {t('healthIssueFlow.healthIssueInfo.textarea.label')}
            </FormLabel>

            <Textarea
              value={inputValue as string}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder={t(
                'healthIssueFlow.healthIssueInfo.textarea.placeholder',
              )}
            />
          </FormControl>
        </CardContentStack>

        <HealthIssueItemSubmit variables={variables} />
      </>
    );
  },
);

export default HealthIssueGeneralDetails;
