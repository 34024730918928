import React from 'react';
import parse, { HTMLReactParserOptions, domToReact } from 'html-react-parser';
import type { DOMNode } from 'html-react-parser';
import { Element, Text } from 'domhandler/lib/node';
import { Link, Icon } from '@chakra-ui/react';
import { IoOpen } from 'react-icons/io5';
import DOMPurify from 'dompurify';
import LinkifyIt from 'linkify-it';

const richTextParser = (content: [string]) => {
  const linkify = new LinkifyIt();

  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      // Find and replace all urls with actual links
      if (domNode instanceof Text) {
        const text = domNode.data;
        if (linkify.pretest(text) && linkify.test(text)) {
          const matches = linkify.match(text) || [];
          for (const match of matches) {
            // Check that Text is not inside of `a` tag (`a` tags cannot be nested)
            if (aTagAsParent(domNode)) return;

            return (
              <>
                {text.substr(0, match.index)}
                <Link href={match.url} isExternal={true}>
                  {match.text}
                </Link>
                {text.substr(match.lastIndex)}
              </>
            );
          }
        }
      }

      // Find and replace all a tags with Link elements
      if (domNode instanceof Element && domNode.attribs) {
        if (domNode.name === 'a' && domNode.attribs.href) {
          let linkContent = domToReact(domNode.children, options);

          // If a tag has no content at all use ExternalLinkIcon
          if (Array.isArray(linkContent) && linkContent.length === 0)
            linkContent = ExternalLinkIcon();

          // If a tag has empty span element use ExternalLinkIcon
          if (
            React.isValidElement(linkContent) &&
            linkContent.type === 'span'
          ) {
            // Check that span element has no children, i.e. it's empty
            const children = (linkContent.props as
              | { children: unknown }
              | undefined)?.children;

            if (children == null) linkContent = ExternalLinkIcon();
          }

          return (
            <Link href={domNode.attribs.href} isExternal={true}>
              {linkContent}
            </Link>
          );
        }
      }

      return;
    },
  };

  const parsedContent = content.map((item, idx) => {
    if (typeof item !== 'string') return null;
    return (
      <React.Fragment key={idx}>
        {parse(DOMPurify.sanitize(item), options)}
      </React.Fragment>
    );
  });

  return parsedContent;
};

/** Traverse recursively up the dom tree to find if there are any `a` tags in
 *  node's parents
 *
 * @param domNode Node to start traversal
 * @returns True if `a` tag exists among node's parents, otherwise false
 */
function aTagAsParent(domNode: DOMNode): boolean {
  // Check if current node is `a` tag
  if (domNode.type === 'tag' && 'name' in domNode && domNode.name === 'a')
    return true;

  // Check if parent is `a` tag
  if (domNode.parent != null) return aTagAsParent(domNode.parent);

  return false;
}

function ExternalLinkIcon() {
  return (
    <Icon as={IoOpen} color="primary.400" w={6} h={6} marginTop="-0.3rem" />
  );
}

export default richTextParser;
