import React, { useContext } from 'react';
import { Heading, Text, Image, Button } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';

import { CardContentStack } from '~components/Card';
import ParagraphStack from '~components/ParagraphStack';
import HealthIssueItemSubmit from '../HealthIssueItemSubmit';
import HeaderImage from '~images/svg/symptom-inquiry.svg';
import { AppContext } from '~state';

const HealthIssueInstructions: React.FC<{ answerId: string }> = ({
  answerId,
}) => {
  const { t } = useTranslation();
  const { dispatch } = useContext(AppContext);

  return (
    <>
      <CardContentStack>
        <Heading as="h1" textStyle="cardHeading">
          {t('healthIssueFlow.instructions.title')}
        </Heading>

        <Image
          src={HeaderImage}
          alt=""
          display={['none', null, 'block']}
          maxWidth={[null, null, '200px', '250px']}
          py={[null, null, '4', '6']}
        />

        <ParagraphStack center>
          <Text>{t('healthIssueFlow.instructions.firstParagraph')}</Text>

          <Text>
            <Trans i18nKey="healthIssueFlow.instructions.highlightedContent">
              <strong>ohje</strong>.
            </Trans>
          </Text>

          <Text marginBottom={2}>
            <Trans i18nKey="healthIssueFlow.instructions.lastParagraph">
              <Button
                variant="link"
                onClick={() =>
                  dispatch({
                    type: 'TOGGLE_INFO_MODAL_STATE',
                  })
                }
                verticalAlign="initial"
              >
                Lue lisäohjeita
              </Button>
              .
            </Trans>
          </Text>
        </ParagraphStack>
      </CardContentStack>

      <HealthIssueItemSubmit variables={{ id: answerId, answer: [] }} />
    </>
  );
};

export default HealthIssueInstructions;
