import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { BsArrowRightShort } from 'react-icons/bs';
import { isMobileOnly } from 'react-device-detect';

import { closeSessionAnswerObject, healthIssueRoot } from '~constants';
import { cardBorderRadius } from '~theme/constants';
import { AnswerInput, Exact, useAddAnswerMutation } from '~api/index';
import { AppContext } from '~state';

interface HealthIssueItemSubmitProps {
  variables:
    | Exact<{
        id: string;
        answer: AnswerInput | AnswerInput[];
      }>
    | undefined;
  label?: string;
  closeSession?: boolean;
}

const HealthIssueItemSubmit: React.FC<
  HealthIssueItemSubmitProps & ButtonProps
> = ({ disabled, label, variables, closeSession, ...rest }) => {
  const { dispatch } = useContext(AppContext);
  const { t } = useTranslation();
  const history = useHistory();
  const [itemOpacity, setItemOpacity] = useState<number>(0);
  const showArrow = !label;

  if (!variables || !variables.id) return null;

  const answerOnCloseSession: AnswerInput | AnswerInput[] = variables.answer
    ? Array.isArray(variables?.answer)
      ? [...variables.answer, closeSessionAnswerObject]
      : [variables.answer, closeSessionAnswerObject]
    : closeSessionAnswerObject;

  const submitVariables = closeSession
    ? {
        id: variables.id,
        answer: answerOnCloseSession,
      }
    : variables;

  useEffect(() => {
    setTimeout(() => {
      setItemOpacity(1);
    }, 500);
  }, []);

  const [addAnswer, { loading }] = useAddAnswerMutation({
    variables: submitVariables,
    onError() {
      dispatch({
        type: 'SET_APP_LOADING_STATE',
        payload: false,
      });
    },
    onCompleted(data) {
      dispatch({
        type: 'SET_CURRENT_FLOW_ITEM',
        payload: data.addAnswer,
      });

      if (closeSession) {
        history.push('/');

        return dispatch({
          type: 'SET_APP_LOADING_STATE',
          payload: false,
        });
      }

      history.push(
        `/${healthIssueRoot}/${data.addAnswer.flowItem?.name}/${data.addAnswer.id}`,
      );

      return dispatch({
        type: 'SET_APP_LOADING_STATE',
        payload: false,
      });
    },
  });

  return (
    <Button
      onClick={(e) => {
        e.preventDefault();

        dispatch({
          type: 'SET_APP_LOADING_STATE',
          payload: true,
        });

        addAnswer();
      }}
      isDisabled={disabled}
      isLoading={loading}
      rightIcon={
        showArrow ? (
          <BsArrowRightShort
            fontSize="2.5rem"
            style={{ marginRight: '-.7rem' }}
          />
        ) : undefined
      }
      borderRadius={
        isMobileOnly
          ? 'full'
          : ['full', null, `0 0 ${cardBorderRadius} ${cardBorderRadius}`]
      }
      bottom={isMobileOnly ? '1rem' : ['1rem', null, 'initial']}
      display={
        disabled ? (isMobileOnly ? 'none' : ['none', null, 'flex']) : ['flex']
      }
      minWidth={isMobileOnly ? '10rem' : ['10rem', null, 'initial']}
      opacity={isMobileOnly ? itemOpacity : [itemOpacity, null, '1']}
      position={isMobileOnly ? 'fixed' : ['fixed', null, 'static']}
      right={isMobileOnly ? '1rem' : ['1rem', null, 'initial']}
      width={isMobileOnly ? 'auto' : ['auto', null, '100%']}
      zIndex="docked"
      {...rest}
    >
      {label ?? t('common.continue')}
    </Button>
  );
};

export default HealthIssueItemSubmit;
