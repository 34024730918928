import React from 'react';
import { Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import ParagraphStack from '~components/ParagraphStack';
import SelectWithShortcuts from '~components/formComponents/SelectWithShortcuts';
import { CardContentStack } from '~components/Card';
import { FlowItem, SelectQuestion } from '~api';

const HealthIssueSymptomSelect: React.FC<FlowItem> = React.memo(
  ({ title, description, question }) => {
    if (!question) return null;

    const { t } = useTranslation();
    const { options, shortcuts } = question as SelectQuestion;

    return (
      <CardContentStack>
        <Heading as="h1" textStyle="cardHeading">
          {title ?? t('healthIssueFlow.symptomSelect.title')}
        </Heading>

        {description && (
          <ParagraphStack content={description as [string]} center />
        )}

        {options && (
          <SelectWithShortcuts options={options} shortcuts={shortcuts} />
        )}
      </CardContentStack>
    );
  },
);

export default HealthIssueSymptomSelect;
