import React from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  useRadio,
  useRadioGroup,
  VisuallyHidden,
} from '@chakra-ui/react';
import { IoIosStar, IoIosStarOutline } from 'react-icons/io';
import { useTranslation } from 'react-i18next';

interface RatingOptionsProps {
  ratingValue?: number;
  setRatingValue: React.Dispatch<React.SetStateAction<number | undefined>>;
  ratingOptions?: [{ value: number; label: string }];
}

export const RadioOption = ({ ...props }) => {
  const { showAsChecked } = props;
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" cursor="pointer">
      <input {...input} />

      <Box {...checkbox} aria-hidden={false}>
        <VisuallyHidden>{props.label}</VisuallyHidden>
        <Box
          as={showAsChecked ? IoIosStar : IoIosStarOutline}
          color={showAsChecked ? 'primary.500' : 'gray.500'}
          size="2rem"
        />
      </Box>
    </Box>
  );
};

const RatingOptions: React.FC<RatingOptionsProps> = ({
  ratingValue,
  setRatingValue,
  ratingOptions,
}) => {
  const { t } = useTranslation();
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'feedbackRating',
    defaultValue: undefined,
    onChange: setRatingValue as any,
  });
  const group = getRootProps();

  const localizedRatingOptions = t('feedback.rating.options', {
    returnObjects: true,
  });

  const defaultOptions = Object.entries(localizedRatingOptions).map(
    ([key, value]) => ({
      value: parseInt(key),
      label: value,
    }),
  );

  const options = ratingOptions ?? defaultOptions;

  return (
    <FormControl id="feedbackRating">
      <FormLabel as="legend" textAlign="center" mx="0" fontWeight="bold">
        {t('feedback.rating.label')}
      </FormLabel>

      <HStack spacing={2} justifyContent="center" {...group}>
        {options.map(({ value, label }) => {
          const radio = getRadioProps({ value: value });

          return (
            <RadioOption
              key={`rating-option-${value}`}
              showAsChecked={ratingValue && ratingValue >= value}
              label={label}
              {...radio}
            />
          );
        })}
      </HStack>
    </FormControl>
  );
};

export default RatingOptions;
